.eatery_page {
  position: relative;
  width: 100%;
  max-width: 1400px;
  display: flex;
  justify-content: center;
  margin: 100px auto 0px auto;
}

.eatery_hero {
  width: 100%;
  height: 500px;
  background-image: url("https://res.cloudinary.com/dcinefb4x/image/upload/v1704605660/eatery_hero_rev1_xy3xzm.png");
  background-repeat: no-repeat;
  background-size: 100% 500px;
}

.eatery_notification {
  width: 100%;
  max-width: 400px;
  margin: 120px auto 0px auto;
  display: flex;
}

.eatery_notification_container {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 10px;
  border-radius: 10px;
}

.eatery_notification_text {
  margin-top: 20px;
  color: #fff;
}

.eatery_page_input {
  width: 70%;
}

.eatery_notification_wrap {
  display: flex;
  margin-top: 20px;
}

@media (max-width: 567px) {
  .eatery_hero {
    background-image: url("https://res.cloudinary.com/dcinefb4x/image/upload/v1704644920/eatery_hero_rev1.5_gg0evj.png");
  }
}
