.category_header {
  width: 100%;
  height: 48px;
  background-color: lightblue;
  display: flex;
  align-items: center;
  /* top: 600px; */
}

.category_menu {
  width: 100%;
  display: flex;
}
/* navbar */
.category_header_container {
  display: flex;
  width: 100%;
}

.category_navbar_container {
  display: flex;
  margin: 0 auto;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  height: 48px;
  position: absolute;
  background-color: lightblue;
  z-index: 10;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  bottom: 0px;
}

/* .category_navbar {
  height: 48px;
  width: 100%;
  background-color: lightblue;
  position: relative;
  display: flex;
  justify-content: center;
  position: absolute;
  bottom: -50px;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  z-index: 10;
} */

.category_btn {
  height: 38px;
  width: 120px;
  background-color: #000;
  border-radius: 5px;
  color: #fff;
  border: none;
  font-size: 1.2rem;
  font-weight: 400;
}

.category_nav_items {
  display: flex;
  width: 100%;
}

.navbar_item {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 130px;
  height: 160px;
  padding-top: 20px;
  font-size: 22px;
  font-weight: 600;
  color: var(--pri-blue);
}

.navbar_image {
  width: 110px;
  height: 110px;
  transition: transform 0.2s; /* Animation */
  border-radius: 50%;
  box-shadow: var(--shadow-2);
  object-fit: cover;
  cursor: pointer;
}

.navbar_image:hover {
  transform: scale(1.1);
}

/* category items */
.category_actions {
  display: flex;
}
.category_items {
  background-color: #fff;
  margin-bottom: 20px;
  border-bottom: 1px solid var(--border-color);
}

/* .category_image {
  height: 120px;
  width: 120px;
  margin-right: 10px;
} */
.category_title {
  color: blue;
  font-size: 16px;
  font-weight: 400;
}
.dropdown {
  background-color: #fff;
  width: 300px;
  border-radius: 10px;
  margin-left: 10px;
  box-shadow: var(--shadow-2);
}

.dropdown_item {
  display: flex;
}

.dropdown_title {
  font-size: 16px;
  font-weight: 400;
}

.right_notification {
  position: absolute;
  top: -6px;
  right: -5px;
  background: #e41e3f;
  border-radius: 50px;
  padding: 1px 6px;
  font-size: 13px;
  color: #fff;
}

/* ----------- media queries ------------ */

@media screen and (max-width: 1600px) {
  .navbar_item {
    margin: 0px 85px;
  }
}

@media screen and (max-width: 1032px) {
  .category_navbar {
    bottom: 220px;
  }

  .navbar_item {
    margin: 0px 65px;
    width: 110px;
  }

  .navbar_image {
    width: 100px;
    height: 100px;
    margin-top: 10px;
  }
}

@media screen and (max-width: 920px) {
  .navbar_image {
    width: 90px;
    height: 90px;
  }
  .navbar_item {
    width: 100px;
    margin: 0px 40px;
  }
}

@media screen and (max-width: 668px) {
  .navbar_item {
    width: 100px;
    margin: 0px 15px;
    padding-top: 20px;
  }

  .navbar_image {
    width: 90px;
    height: 90px;
  }

  .category_navbar_container {
    height: 40px;
  }
}

@media screen and (max-width: 518px) {
  /* .navbar_item {
    width: 100px;
    margin: 35px 5px 0px 5px;
  } */

  .navbar_image {
    width: 90px;
    height: 90px;
  }
}

@media screen and (max-width: 480px) {
  .navbar_item {
    width: 80px;
    margin: 20px 10px 0px 10px;
    font-size: 1.3rem;
  }

  .navbar_image {
    width: 80px;
    height: 80px;
  }
}

@media screen and (max-width: 436px) {
  .navbar_item {
    width: 80px;
    margin: 30px 10px 0px 10px;
    font-size: 1.3rem;
  }

  .navbar_image {
    width: 70px;
    height: 70px;
  }
}

@media screen and (max-width: 370px) {
  .navbar_item {
    width: 80px;
    margin: 30px 5px 0px 5px;
    font-size: 1.3rem;
  }

  .navbar_image {
    width: 70px;
    height: 70px;
  }
}
/* ----------- media queries ------------ */
