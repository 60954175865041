.spinner {
  position: fixed;
  inset: 0;
  background-color: white;
  opacity: 70%;
  z-index: 100;
  display: flex;
  justify-content: center;
  align-items: center;
}

.spinner_container {
  width: 100px;
  height: 100px;
  border-top: transparent;
  border-left: 3px dashed var(--pri-blue);
  border-right: 3px dashed var(--pri-blue);
  border-bottom: 3px dashed var(--pri-blue);
  border-radius: 60%;
  animation-name: rotation;
  animation-duration: 4s;
  animation-iteration-count: infinite;
  transform: rotate(45deg);
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  25% {
    transform: rotate(90deg);
  }
  50% {
    transform: rotate(180deg);
  }
  75% {
    transform: rotate(270deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
