.home {
  min-height: 100vh;
  background: var(--bg-blue);
  display: flex;
  position: relative;
}

.home_middle {
  margin-top: 75px;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

@media (max-width: 1175px) {
  .stories,
  .createPost,
  .post,
  .send_verification {
    width: 84%;
  }
  .home_middle {
    margin-left: 5%;
  }
  .right_home {
    width: 18vw;
  }
}
