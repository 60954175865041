@import url("https://fonts.googleapis.com/css2?family=Dancing+Script:wght@400;500;600;700&display=swap");

/*----------------------- Tr Hero ---------------------------- */

.tr_hero {
  width: 99%;
  max-width: 1200px;
  height: 500px;
  position: relative;
  display: flex;
  margin: 100px auto 90px auto;
}

.tr_hero_title {
  position: absolute;
  top: 20px;
  left: 6%;
  font-size: 4rem;
  font-weight: 100;
}

.tr_hero_title span {
  font-size: 5rem;
  font-weight: 400;
  font-family: "Dancing Script", cursive;
}

.tr_hero_name {
  position: absolute;
  top: 0px;
  left: 22%;
  font-size: 1.5rem;
}

.hero_wrap {
  position: absolute;
  bottom: 0;
  background-color: #fff;
  width: 60%;
  height: 340px;
  border-radius: 20px;
  padding: 20px;
  box-shadow: 5px 10px 15px var(--shadow-2);
}

.hero_wrap p {
  position: absolute;
  left: 13%;
  top: 140px;
  width: 500px;
  font-size: 1.1rem;
}

.tr_hero_image {
  position: absolute;
  left: 60%;
  top: 45px;
  height: 500px;
  width: 480px;
}

.tr_hero_image_small {
  position: absolute;
  left: 40%;
  top: -30px;
  height: 150px;
  width: 180px;
  background-image: url("https://res.cloudinary.com/dcinefb4x/image/upload/v1705297549/assets/marriage-slate-rev-2_lxmdv4.png");
  background-position: center;
  background-repeat: none;
  background-size: cover;
}

.tr_hero_button {
  position: absolute;
  top: 440px;
  left: 7.5%;
}

/* ----------------------- Tr Hero ---------------------------- */

/* ----------------------- Media Queries ---------------------------- */
@media (max-width: 1170px) {
  .tr_hero_title {
    left: 3%;
  }

  .tr_hero_name {
    left: 20%;
  }

  .hero_wrap {
    width: 58%;
  }

  .hero_wrap p {
    left: 11%;
  }

  .tr_hero_image {
    left: 58%;
  }
}

@media (max-width: 1078px) {
  .tr_hero_title {
    left: 2%;
  }

  .tr_hero_name {
    left: 18%;
  }

  .hero_wrap {
    width: 58%;
  }

  .hero_wrap p {
    left: 10%;
  }

  .tr_hero_image {
    left: 57%;
  }
}

@media (max-width: 1056px) {
  .tr_hero_title {
    left: 1%;
  }

  .tr_hero_name {
    left: 18%;
  }

  .tr_hero_image_small {
    left: 37%;
  }

  .hero_wrap {
    width: 56%;
    left: 1%;
  }

  .hero_wrap p {
    left: 8%;
  }

  .tr_hero_image {
    left: 56%;
  }
}

@media (max-width: 1022px) {
  .tr_hero_title {
    left: 1%;
    font-size: 3.8rem;
  }

  .hero_wrap {
    width: 56%;
  }

  .tr_hero_image {
    left: 56%;
    width: 45%;
  }
}

@media (max-width: 1000px) {
  .hero_wrap p {
    left: 6%;
  }
}

@media (max-width: 984px) {
  .tr_hero_title {
    font-size: 3.6rem;
  }

  .tr_hero_image_small {
    left: 35%;
  }

  .hero_wrap p {
    left: 4%;
  }
}

@media (max-width: 954px) {
  .tr_hero_title {
    font-size: 3.4rem;
    margin-top: 10px;
  }

  .tr_hero_title span {
    font-size: 4.6rem;
  }

  .tr_hero_name {
    font-size: 1.4rem;
  }

  .tr_hero_image_small {
    left: 35%;
  }

  .hero_wrap p {
    left: 4%;
    width: 460px;

    font-size: 1rem;
  }
}

@media (max-width: 900px) {
  .tr_hero_title {
    font-size: 4.4rem;
    margin-top: 10px;
    left: 18%;
  }

  .tr_hero_title span {
    font-size: 5rem;
  }

  .tr_hero_name {
    font-size: 1.5rem;
    left: 40%;
  }

  .tr_hero_image {
    display: none;
  }

  .hero_wrap {
    width: 94%;
    height: 300px;
    left: 3%;
  }

  .hero_wrap p {
    left: 8%;
    top: 160px;
    width: 84%;
    font-size: 1.2rem;
  }

  .tr_hero_image_small {
    left: 30%;
    top: -70px;
    height: 210px;
    width: 360px;
    background-image: url("https://res.cloudinary.com/dcinefb4x/image/upload/v1706795057/assets/basket-slate-rev-1_mytfe8.png");
  }
}

@media (max-width: 856px) {
  .tr_hero_title {
    font-size: 4.4rem;
    margin-top: 10px;
    left: 16%;
  }
}

@media (max-width: 796px) {
  .tr_hero_title {
    font-size: 4.4rem;
    margin-top: 10px;
    left: 12%;
  }
}

@media (max-width: 756px) {
  .tr_hero_title {
    font-size: 4.4rem;
    margin-top: 10px;
    left: 9%;
  }

  .tr_hero_name {
    left: 36%;
  }

  .hero_wrap p {
    left: 3%;
    top: 160px;
    width: 96%;
    font-size: 1.1rem;
  }

  .tr_hero_image_small {
    left: 26%;
  }
}

@media (max-width: 702px) {
  .tr_hero_title {
    left: 7%;
  }

  .tr_hero_image_small {
    left: 24%;
  }
}

@media (max-width: 688px) {
  .tr_hero_title {
    left: 4%;
  }

  .tr_hero_image_small {
    left: 22%;
  }
}

@media (max-width: 644px) {
  .tr_hero_title {
    left: 2%;
    font-size: 4.2rem;
  }

  .tr_hero_image_small {
    left: 20%;
  }

  .hero_wrap p {
    left: 3%;
    top: 160px;
    width: 94%;
    font-size: 1.1rem;
  }
}

@media (max-width: 614px) {
  .tr_hero_title {
    left: 2%;
    font-size: 4rem;
  }
}

@media (max-width: 576px) {
  .tr_hero_title {
    left: 2%;
    font-size: 3.6rem;
  }

  .tr_hero_image_small {
    left: 18%;
  }
}

@media (max-width: 552px) {
  .tr_hero_title {
    left: 3%;
    font-size: 3.4rem;
  }

  .tr_hero_title span {
    font-size: 4.6rem;
  }

  .tr_hero_name {
    left: 32%;
  }

  .hero_wrap p {
    left: 3%;
    font-size: 1rem;
  }

  .tr_hero_image_small {
    left: 14%;
  }
}

@media (max-width: 536px) {
  .tr_hero_title {
    margin-top: 15px;
    font-size: 3.2rem;
    left: 4%;
  }

  .tr_hero_title span {
    font-size: 4.2rem;
  }

  .tr_hero_name {
    left: 32%;
  }

  .hero_wrap p {
    left: 3%;
    font-size: 1rem;
  }

  .tr_hero_image_small {
    left: 12%;
    top: -80px;
  }
}

@media (max-width: 496px) {
  .tr_hero_title {
    left: 3%;
    font-size: 2.8rem;
  }

  .tr_hero_image_small {
    left: 8%;
  }
}

@media (max-width: 460px) {
  .tr_hero_title {
    left: 8%;
    font-size: 2.4rem;
  }

  .tr_hero_name {
    font-size: 1.2rem;
  }

  .tr_hero_image_small {
    left: 8%;
  }

  .tr_hero_title span {
    font-size: 3.4rem;
  }

  .tr_hero_image_small {
    left: 8%;
    top: -100px;
  }

  .hero_wrap p {
    left: 3%;
    top: 130px;
  }
}

@media (max-width: 446px) {
  .tr_hero_image_small {
    left: 6%;
  }
}

@media (max-width: 424px) {
  .tr_hero_title {
    left: 6%;
  }

  .tr_hero_image_small {
    left: 3%;
  }
}

@media (max-width: 402px) {
  .tr_hero_title {
    left: 2%;
  }

  .tr_hero_image_small {
    left: 2%;
    background-size: 98%;
  }

  .hero_wrap {
    width: 98%;
    left: 1%;
  }
}

@media (max-width: 374px) {
  .tr_hero_title {
    font-size: 2.2rem;
    left: 3%;
  }

  .tr_hero_image_small {
    left: 0%;
    background-size: 97.5%;
  }
}

@media (max-width: 360px) {
  .tr_hero_name {
    font-size: 1.1rem;
  }

  .tr_hero_title {
    font-size: 2rem;
    left: 3%;
  }

  .tr_hero_image_small {
    left: 0%;
  }
}

/* ----------------------- Media Queries ---------------------------- */
