.ride_page {
  position: relative;
  width: 100%;
  max-width: 1400px;
  display: flex;
  justify-content: center;
  margin: 100px auto 0px auto;
}

.ride_hero {
  width: 100%;
  height: 500px;
  background-image: url("https://res.cloudinary.com/dcinefb4x/image/upload/v1708362065/samuele-errico-piccarini-MyjVReZ5GLQ-unsplash_d0rqza.jpg");
  background-repeat: no-repeat;
  background-size: 100% 500px;
}

.ride_notification {
  width: 100%;
  max-width: 400px;
  margin: 120px auto 0px auto;
  display: flex;
}

.ride_notification_container {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 20px;
  border-radius: 10px;
  z-index: 100;
  background-color: #000000;
  border: 1px solid #fff;
}

.ride_notification_text {
  margin-top: 20px;
  color: #fff;
}

.ride_page_input {
  width: 70%;
}

.ride_notification_wrap {
  display: flex;
  margin-top: 20px;
}

.ride_hero_grad {
  background-color: #00000099;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 500px;
  z-index: 10;
}

@media (max-width: 567px) {
  .ride_hero {
    padding: 20px;
    background-image: url("https://res.cloudinary.com/dcinefb4x/image/upload/v1708362065/samuele-errico-piccarini-MyjVReZ5GLQ-unsplash_d0rqza.jpg");
  }
}
