@import url("https://fonts.googleapis.com/css2?family=Caveat");

.truly_remembered_page {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 1200px;
  margin: -60px auto 0 auto;
}

.tr_how_to {
  width: 100%;
  height: 400px;
  max-width: 1200px;
  margin-top: 60px;
  border-radius: 20px;
}

.tr_email_container {
  width: 100%;
  max-width: 520px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  background-color: #fff;
  padding: 10px 20px;
  box-shadow: 5px 10px 15px var(--shadow-2);
  border-radius: 20px;
  font-size: 24px;
  height: 100px;
}

.tr_email_wrap {
  display: flex;
  align-items: center;
}

/* ----------------------- Tr Subscribe Container ---------------------------- */
.tr_subscribe_container {
  position: relative;
  width: 100%;
  height: 400px;
  max-width: 1200px;
  margin-top: 60px;
  border-radius: 20px;
  background-color: #fff;
  border-radius: 20px;
  padding: 10px;
}

.tr_subscribe_left {
  position: absolute;
  left: 10px;
  width: 45%;
  display: flex;
  flex-direction: column;
  height: 380px;
}

.tr_subscribe_right {
  position: absolute;
  width: 45%;
  height: 380px;
  right: 0px;
}

.tr_subscribe_title_wrap {
  position: absolute;
  left: 24%;
  display: flex;
  flex-direction: column;
  text-align: center;
  color: #7c7c7c;
}

.tr_subscribe_title {
  font-family: "Caveat", cursive;
  font-size: 4rem;
}
.tr_subscribe_subtitle {
  font-size: 1rem;
  margin-top: -10px;
}

.tr_subscribe_text_wrap {
  position: absolute;
  top: 120px;
  left: 20%;
}

.tr_subscribe_text {
  line-height: 1.4;
}

.tr_subscribe_main_image {
  position: absolute;
  top: -30px;
  right: 0;
  width: 93%;
  height: 460px;
}

/* ----------------------- Tr Subscribe Container ---------------------------- */

/* ----------------------- Media Queries ---------------------------- */

@media (max-width: 656px) {
  .tr_email_container {
    width: 92%;
    max-width: 600px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    background-color: #fff;
    padding: 20px;
    box-shadow: 5px 10px 15px var(--shadow-2);
    border-radius: 20px;
  }

  .tr_email_container input {
    width: 98%;
  }
}

/* ----------------------- Media Queries ---------------------------- */
