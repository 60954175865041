.book_appointment {
  margin-top: 49px;
  width: 100%;
  background-color: #fff;
}

.ba_container {
  position: relative;
  width: 100%;
  max-width: 1200px;
  height: 80vh;
  display: flex;
  margin: 50px auto 0 auto;
}

.ba_wrap {
  position: relative;
  width: 100%;
  display: flex;
  margin-top: 50px;
  justify-content: space-between;
  padding: 0px 20px;
}

.ba_wrap_left {
  position: relative;
  width: 50%;
  height: 600px;
  padding: 0px 0px;
}

.ba_wrap_right {
  position: relative;
  width: 45%;
}

.ba_images_wrap {
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin: 0px 10px 0px 0px;
  left: 0;
  width: 18%;
  max-width: 100px;
}

.ba_images {
  background-color: #fff;
  width: 100%;
  height: 100px;
  margin: 10px 10px 10px 0px;
}

.ba_image_select {
  position: absolute;
  right: 0;
  width: 80%;
  height: 560px;
  margin-top: 8px;
  border: 2px solid #fff;
}

.ba_info {
  display: flex;
  flex-direction: column;
}

.ba_title {
  font-size: 24px;
  font-weight: 800;
  cursor: pointer;
  margin-bottom: 10px;
}

.ba_timings {
  color: gray;
  font-size: 14px;
  margin-bottom: 20px;
}

.ba_description {
  margin-bottom: 20px;
}

.ba_reservation {
  margin-bottom: 20px;
}

.ba_date {
  margin-bottom: 20px;
}

/* ------------------ Service Details Info -------------------- */
.sdi {
  background-color: #f5f6f6;
  position: relative;
  padding-bottom: 20px;
}
.sdi_info_details {
  width: 100%;
  max-width: 1200px;
  margin: 60px auto 0px auto;
}
.sdi_info_image {
  width: 40px;
  height: 40px;
  border-radius: 50%;
}

.sdi_experience {
  margin-top: 20px;
}

.sdi_description {
  margin: 20px 0;
}

/* ------------------ Service Details Info -------------------- */

/* ------------------- Media Queries ----------------------- */

@media (max-width: 1400px) {
  .ba_wrap_left {
    width: 50%;
  }
}

@media (max-width: 1152px) {
  .ba_image {
    height: 440px;
  }
}

@media (max-width: 1042px) {
  .ba_images {
    height: 80px;
  }
}

@media (max-width: 818px) {
  .ba_container {
    height: 90vh;
  }
}

@media (max-width: 720px) {
  .ba_images_wrap {
    flex-direction: row;
    bottom: 30px;
    left: 0;
    width: 100%;
    max-width: 360px;
  }

  .ba_image_select {
    width: 100%;
    height: 460px;
  }
}

@media (max-width: 640px) {
  .ba_images_wrap {
    bottom: 70px;
  }

  .ba_image_select {
    height: 420px;
  }
  .ba_description {
    font-size: 0.9rem;
  }
}

@media (max-width: 580px) {
  .ba_container {
    height: 100vh;
  }

  .ba_images_wrap {
    bottom: 130px;
  }

  .ba_image_select {
    height: 360px;
  }
}

@media (max-width: 520px) {
  .ba_images_wrap {
    bottom: 150px;
  }

  .ba_image_select {
    height: 340px;
  }
  .ba_container {
    height: 100vh;
  }
  .ba_description {
    font-size: 0.8rem;
  }
}

@media (max-width: 460px) {
  .ba_images_wrap {
    bottom: 210px;
  }

  .ba_images {
    height: 60px;
  }

  .ba_image_select {
    height: 300px;
  }
}

@media (max-width: 384px) {
  .ba_images_wrap {
    bottom: 270px;
  }

  .ba_images {
    height: 40px;
  }

  .ba_image_select {
    height: 260px;
  }
  .ba_container {
    margin: 50px auto 180px auto;
  }
}

/* ------------------- Media Queries ----------------------- */
