.hero {
  position: relative;
  display: flex;
  width: 99%;
  max-width: 1400px;
  height: 500px;
  justify-content: center;
  margin: 74px auto 0px auto;
  flex-direction: column;
  /* background-image: url("https://res.cloudinary.com/dcinefb4x/image/upload/v1700326001/hero_rev3.5_rnpjj7.png"); */
  background-image: url("https://res.cloudinary.com/dcinefb4x/image/upload/v1700326001/hero_rev3.5_rnpjj7.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 98% 500px;
}

.beta {
  position: absolute;
  top: -20px;
  left: 50%;
  color: var(--pri-blue);
  z-index: 5000;
  font-size: 20px;
}

.hero_title {
  position: absolute;
  top: 0px;
  left: 10px;
  font-size: 84px;
  /* font-size: 100%; */
  font-weight: 600;
  /* width: 100%; */
  /* height: 3vh; */
}

.hero_sub_title {
  position: absolute;
  left: 40px;
  top: 160px;
  z-index: 10;
  font-size: 46px;
}

.hero_sub_title_wrap {
  position: relative;
  top: -40px;
}

.hero_sub_title p {
  position: absolute;
  top: 110px;
  font-size: 22px;
  width: 100%;
}

.hero_sub_title_product_image_wrap {
  position: absolute;
  top: 8px;
  left: 480px;
  height: 40px;
  width: 124px;
  display: flex;
}

.hero_sub_title_product_image {
  height: 45px;
  width: 45px;
  border-radius: 5px;
  border: 2px solid #fec93a;
}

.hst_span_1 {
  margin-left: 120px;
}

.hst_span_1 br {
  display: none;
}

.hst_span_2 {
  margin-left: 0px;
}

.hst_span_3 {
  margin-left: 120px;
}

.hst_span_3 br {
  display: none;
}

.hero_sub_title_more_text {
  margin-left: 115px;
}

.hero_sub_title_find_text {
  position: absolute;
  top: 160px;
}

.hero_sub_title_product_image:nth-child(1) {
  margin-right: -15px;
}

.hero_sub_title_product_image:nth-child(2) {
  margin-right: -15px;
}

.hero_sub_title_service_image_wrap {
  position: absolute;
  top: 70px;
  left: 300px;
  height: 50px;
  width: 124px;
  display: flex;
}

.hero_sub_title_service_image {
  height: 45px;
  width: 45px;
  border-radius: 50%;
  border: 2px solid #fec93a;
}
.hero_sub_title_service_image:nth-child(1) {
  margin-right: -15px;
}
.hero_sub_title_service_image:nth-child(2) {
  margin-right: -15px;
}

.hero_sub_title_eateries_image_wrap {
  position: absolute;
  top: 80px;
  left: 170px;
}

.hero_sub_title_eateries_image {
  height: 55px;
  width: 125px;
  background-image: url("https://res.cloudinary.com/dcinefb4x/image/upload/v1704944971/Small_Eatery_Images_3_uy9cpd.png");
  object-fit: contain;
  background-position: center;
  background-repeat: no-repeat;
  background-size: 105px 55px;
}

.hero_sub_title_eateries_image:nth-child(1) {
  margin-right: -15px;
}
.hero_sub_title_eateries_image:nth-child(2) {
  margin-right: -15px;
}

.hero_sub_title_like_image {
  position: absolute;
  top: 72px;
  left: 515px;
  height: 40px;
  width: 40px;
}

.hero_button_container {
  position: absolute;
  bottom: 50px;
  left: 30px;
  display: flex;
  justify-content: space-between;
  width: 40%;
  /* margin-left: 60px; */
  z-index: 20;
}

.hero_button {
  height: 40px;
  width: 120px;
  padding: 5px 10;
  border-radius: 20px;
  background-color: var(--pri-blue);
  color: #fff;
  font-size: 1.2rem;
  border: none;
  box-shadow: 0 1px 2px var(--shadow-2);
  cursor: pointer;
}

.hero_background {
  /* width: 100%;
  position: absolute;
  top: 0px;
  background-position: center;
  border-radius: 20px;
  margin-top: 0px;
  height: 500px; */
}

.hero_image {
  width: 480px;
  height: 440px;
  position: absolute;
  top: 20px;
  right: 10px;
  z-index: 5;
}

.hero_nav_bar_container {
  display: none;
}

.hero_social_wrap {
  position: absolute;
  display: flex;
  align-items: center;
  left: 60px;
  bottom: 50px;
  z-index: 25;
}

.hero_social_icon {
  height: 30px;
  width: 30px;
  color: #00000080;
}

.hero_social_icon:nth-child(2) {
  margin-left: 10px;
  height: 35px;
  width: 35px;
}
.hero_social_icon:nth-child(3) {
  margin-left: 10px;
  height: 35px;
  width: 35px;
}

.hero-input_container {
  width: 40%;
  max-width: 1024px;
  position: absolute;
  bottom: 150px;
  left: 30%;
  display: flex;
  align-items: center;
  gap: 10px;
  background-color: white;
  border-radius: 20px;
  border: 3px solid lightblue;
  padding: 5px;
}

.hero-search_item {
  justify-content: center;
  display: flex;
  align-items: center;
  gap: 10px;
}

.hero-input {
  color: gray;
  outline: none;
  /* width: 50%; */
  padding: 5px;
  font-size: 18px;
  border: none;
}

.search-button {
  border-radius: 50%;
  width: 35px;
  height: 35px;
  background-color: lightblue;
  border: none;
  color: white;
  position: absolute;
  right: 10px;
}

/* -------------------- media queries ----------------- */

@media (max-width: 1400px) {
  .hero_title {
    font-size: 6vw;
  }
}

@media (max-width: 1360px) {
  .hero_image {
    right: 0;
  }
}

@media (max-width: 1300px) {
  .hero_image {
    right: -10px;
    width: 37%;
  }
}

@media screen and (max-width: 1272px) {
  .hero_button {
    width: 110px;
  }
}

@media screen and (max-width: 1246px) {
  .hero_sub_title {
    font-size: 42px;
    left: 30px;
    top: 170px;
  }
  .hero_sub_title p {
    top: 100px;
    font-size: 20px;
  }

  .hero_sub_title_product_image_wrap {
    left: 440px;
    top: 5px;
  }
  .hero_sub_title_service_image_wrap {
    left: 265px;
    top: 60px;
  }

  .hst_span_3 {
    margin-left: 110px;
  }
}

@media screen and (max-width: 1228px) {
  .hero_title {
    font-size: 4.6em;
    top: 10px;
  }
}

@media screen and (max-width: 1204px) {
  .hero_title {
    font-size: 4.5em;
  }
}

@media screen and (max-width: 1188px) {
  .hero_title {
    font-size: 4.3em;
  }
}

@media screen and (max-width: 1144px) {
  .hero {
    background-size: 98% 450px;
  }
  .hero_title {
    top: 20px;
  }

  .hero_image {
    top: 36px;
    right: -10px;
    width: 37%;
    height: 400px;
  }

  .hero_button_container {
    justify-content: flex-start;
    bottom: 65px;
  }
  .hero_button {
    width: 95px;
    margin-right: 20px;
  }
}
@media (max-width: 1130px) {
  .hero_title {
    font-size: 4.2em;
  }
}

@media (max-width: 1110px) {
  .hero_sub_title {
    font-size: 40px;
    left: 25px;
  }

  .hero_sub_title_product_image_wrap {
    left: 420px;
    top: 0px;
  }
  .hero_sub_title_service_image_wrap {
    left: 255px;
    top: 60px;
  }

  .hero_button_container {
    left: 15px;
  }
  .hero_button {
    width: 95px;
    margin-right: 15px;
  }
}
@media (max-width: 1102px) {
  .hero_title {
    font-size: 4em;
    top: 25px;
  }
}

@media (max-width: 1070px) {
  .hero_sub_title {
    font-size: 38px;
    left: 25px;
  }

  .hero_sub_title p {
    top: 90px;
  }

  .hero_sub_title_product_image_wrap {
    left: 400px;
    top: 0px;
  }
  .hero_sub_title_service_image_wrap {
    left: 240px;
    top: 55px;
  }
}

@media (max-width: 1054px) {
  .hero_title {
    font-size: 3.9em;
    /* top: 25px; */
  }
}

@media (max-width: 1030px) {
  .hero_title {
    font-size: 3.8em;
    top: 30px;
  }

  .hero_sub_title {
    font-size: 36px;
    left: 25px;
  }

  .hero_sub_title p {
    font-size: 19px;
    top: 85px;
  }

  .hero_sub_title_product_image_wrap {
    left: 380px;
  }
  .hero_sub_title_service_image_wrap {
    left: 230px;
    top: 50px;
  }

  .hero_image {
    top: 40px;
  }

  .hero_button {
    width: 90px;
    margin-right: 10px;
    font-size: 1.1em;
  }
}

@media (max-width: 992px) {
  .hero_title {
    font-size: 3.7em;
    top: 35px;
  }

  .hero_sub_title {
    font-size: 34px;
  }

  .hero_sub_title p {
    font-size: 19px;
    top: 85px;
  }

  .hero_sub_title_product_image_wrap {
    left: 360px;
  }
  .hero_sub_title_service_image_wrap {
    left: 214px;
    top: 50px;
  }

  .hero_image {
    top: 50px;
    height: 390px;
  }
}

@media (max-width: 970px) {
  .hero_title {
    font-size: 3.6em;
    top: 40px;
  }
  .hero_image {
    width: 38%;
  }
}

@media (max-width: 948px) {
  .hero_title {
    font-size: 3.5em;
    top: 40px;
  }
}

@media (max-width: 940px) {
  .hero_sub_title_product_image:nth-child(2) {
    display: none;
  }
  .hero_sub_title_service_image:nth-child(2) {
    display: none;
  }

  .hero_sub_title_service_image_wrap {
    left: 215px;
  }

  .hst_span_1 {
    margin-left: 95px;
  }

  .hst_span_2 {
    margin-left: 0px;
  }

  .hst_span_3 {
    margin-left: 80px;
  }

  .hero_sub_title p {
    font-size: 18px;
    top: 90px;
  }

  .hero_button {
    width: 85px;
    margin-right: 10px;
    font-size: 1.1rem;
  }
}

@media (max-width: 922px) {
  .hero_title {
    font-size: 3.4em;
    top: 40px;
  }
}

@media (max-width: 904px) {
  .hero_title {
    font-size: 6vw;
    top: 40px;
  }

  .hero_sub_title_service_image:nth-child(1) {
    display: none;
  }

  .hst_span_3 {
    margin-left: 55px;
  }

  .hero_sub_title p {
    font-size: 17px;
    top: 90px;
  }

  .hero_button {
    width: 80px;
    margin-right: 13px;
    font-size: 1rem;
  }
}

@media (max-width: 880px) {
  .hero_button {
    width: 78px;
    margin-right: 13px;
    font-size: 0.9rem;
  }
}

@media (max-width: 856px) {
  .hero {
    height: 500px;
    background-image: url("https://res.cloudinary.com/dcinefb4x/image/upload/v1701914337/hero_rev1.5_adwxsc.png");
  }

  .hero_title {
    font-size: 9vw;
    top: 0px;
  }

  .hero_sub_title {
    font-size: 30px;
    top: 190px;
  }

  .hero_sub_title p {
    font-size: 16px;
    top: 85px;
  }

  .hero_sub_title p br {
    display: none;
  }

  .hero_image {
    width: 42%;
    height: 390px;
    top: 80px;
    right: 0;
  }

  .hero_sub_title_product_image:nth-child(1) {
    display: none;
  }

  .hst_span_1 {
    margin-left: 65px;
  }

  .hero_button {
    width: 75px;
  }

  .hero_sub_title_product_image_wrap {
    left: 320px;
    top: -5px;
  }
  .hero_sub_title_service_image_wrap {
    left: 190px;
    top: 40px;
  }

  .hst_span_3 {
    margin-left: 50px;
  }

  .hero_button {
    display: none;
  }
  .hero_nav_bar_container {
    display: flex;
    position: absolute;
    bottom: 40px;
    left: 8px;
    width: 98%;
  }
}

@media (max-width: 824px) {
  .hero_button {
    width: 70px;
  }
}

@media (max-width: 810px) {
  .hero_sub_title_product_image_wrap {
    display: none;
  }
  .hero_sub_title_service_image_wrap {
    display: none;
  }

  .hero_sub_title_product_image {
    display: none;
    /* left: 200px; */
  }

  .hero_sub_title_service_image {
    display: none;
    /* left: 120px; */
  }

  .hst_span_1 {
    margin-left: 5px;
  }

  .hst_span_3 {
    margin-left: 5px;
  }

  .hero_sub_title p {
    font-size: 15px;
    width: 99%;
  }
}

@media (max-width: 772px) {
  .beta {
    top: -40px;
  }

  .hero {
    margin: 54px auto 0px auto;
  }

  .hero_title {
    font-size: 9vw;
    top: 26px;
  }

  .hero_image {
    width: 42%;
    height: 370px;
    top: 92px;
    right: -5px;
  }

  .hero_button {
    display: none;
  }
}

@media (max-width: 710px) {
  .hero_sub_title {
    font-size: 30px;
    top: 176px;
  }

  .hero_image {
    width: 46%;
  }

  .hst_span_3 br {
    display: block;
  }

  .hero_sub_title p {
    top: 100px;
  }
}

@media (max-width: 668px) {
  .hero_sub_title {
    font-size: 28px;
  }

  .hero_nav_bar_container {
    left: 6px;
    width: 98.5%;
  }

  .hero_image {
    top: 90px;
    right: -10px;
    width: 49%;
  }
}

@media (max-width: 630px) {
  .hero_sub_title {
    font-size: 26px;
  }

  .hero_sub_title p {
    font-size: 14.4px;
  }

  .hero_title {
    top: 40px;
  }

  .hero_image {
    top: 94px;
    width: 49%;
  }
}

@media (max-width: 600px) {
  .hero_nav_bar_container {
    left: 6px;
    width: 98%;
  }
}
@media (max-width: 594px) {
  .hero_sub_title {
    font-size: 2.4rem;
  }

  .hero_sub_title_product_image_wrap {
    left: 115px;
  }
  .hero_sub_title_service_image_wrap {
    left: 325px;
  }
  .hero_sub_title_eateries_image_wrap {
    left: 120px;
    top: 52px;
  }
}

@media (max-width: 576px) {
  .hero {
    height: 560px;
    justify-content: center;
    margin: 100px auto 0px auto;
    flex-direction: column;
    background-position: center;
    background-repeat: no-repeat;
    background-size: 98% 660px;
  }

  .hero_title {
    display: none;
  }

  .hero_sub_title {
    top: 30px;
    left: 10%;
    font-size: 2rem;
    justify-content: center;
    text-align: center;
  }

  .hst_span_3 br {
    display: none;
  }

  .hero_sub_title p {
    top: 420px;
    font-size: 16px;
  }

  .hero_image {
    top: 90px;
    right: 10%;
    width: 80%;
    height: 360px;
  }

  .hero_nav_bar_container {
    bottom: -36px;
    width: 98%;
  }
}

@media (max-width: 546px) {
  .hero_sub_title {
    left: 8%;
  }
}

@media (max-width: 528px) {
  .hero_sub_title {
    left: 7%;
  }
}

@media (max-width: 510px) {
  .hero_sub_title {
    left: 5%;
  }
}

@media (max-width: 492px) {
  .hero_sub_title {
    left: 4%;
  }

  .hero_image {
    right: 8%;
    width: 86%;
    height: 360px;
  }

  .beta {
    left: 45%;
  }
}

@media (max-width: 486px) {
  .hero_sub_title {
    font-size: 1.9rem;
    left: 5%;
    top: 34px;
  }

  .hero_sub_title p {
    top: 420px;
    font-size: 15px;
  }
}

@media (max-width: 468px) {
  .hero_sub_title {
    left: 4%;
  }
  /* .hero_sub_title_service_image_wrap {
    left: 0px;
    width: 0px;
  }
  .hero_sub_title_eateries_image_wrap {
    left: 0px;
    width: 0;
  } */
}

@media (max-width: 454px) {
  .hero_sub_title {
    left: 9%;
    font-size: 1.6rem;
    top: 44px;
  }

  .hero_sub_title p {
    top: 408px;
    font-size: 14.5px;
  }
}

@media (max-width: 422px) {
  .hero_sub_title {
    left: 6%;
  }

  .hero_image {
    right: 6%;
    width: 90%;
  }
}

@media (max-width: 404px) {
  .hero_sub_title {
    left: 5%;
  }

  .hero_image {
    right: 4%;
    width: 96%;
  }
}

@media (max-width: 396px) {
  .hero_sub_title {
    left: 5%;
  }

  .hero_image {
    right: 0%;
    width: 100%;
  }
}

@media (max-width: 382px) {
  .hero_sub_title {
    font-size: 1.5rem;
  }
}

@media (max-width: 364px) {
  .hero_sub_title {
    left: 4%;
  }
}

/* -------------------- media queries ----------------- */
