.dashboard {
  height: calc(100vh - 56px);
  width: 100%;
  margin-top: 56px;
  display: grid;
  color: var(--color-primary);
}

.dashboard_left {
  position: relative;
  background-color: #fff;
  box-shadow: 0 12px 12px var(--shadow-1);
  padding: 10px;
  position: fixed;
  top: 49px;
  width: 30%;
  max-width: 330px;
  height: 100%;
}

.db_item {
  position: relative;
  display: flex;
  align-items: center;
  padding-left: 10px;
  width: 100%;
  height: 40px;
  margin: 20px 0px;
}

.db_item a {
  display: flex;
  align-items: center;
}

.db_item .dbArrow {
  position: absolute;
  display: block;
  left: 90%;
  top: 8px;
}

/* ------------- Media Queries ---------------- */

@media (max-width: 942px) {
  .dashboard_left {
    width: 28%;
  }
}

@media (max-width: 800px) {
  .db_item span {
    font-size: 14px;
  }
}

@media (max-width: 700px) {
  .dashboard_left {
    width: 13%;
    min-width: 86px;
  }

  .db_item {
    padding-left: 5px;
  }

  .db_item span {
    display: none;
  }

  .db_item .dbArrow {
    position: absolute;
    display: block;
    left: 70%;
    top: 8px;
  }
}

@media (max-width: 500px) {
  .dashboard_left {
    min-width: 65px;
  }

  .db_item .dbArrow {
    display: none;
  }
}

/* ------------- Media Queries ---------------- */
