.payment {
  position: relative;
  width: 100%;
  max-width: 1400px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 70px auto 0px auto;
}

.payment_container {
  width: 80%;
  margin: 0px auto;
  display: flex;
  justify-content: space-between;
  position: relative;
}

.payment_info_wrap {
  width: 50%;
  /* margin-right: 50px; */
  background-color: #fff;
  border-radius: 10px;
  padding: 10px;
}

.card_data_wrap {
  width: 45%;
  height: 20vh;
  background-color: #fff;
  border-radius: 10px;
  padding: 10px;
}

.card_wrap {
  margin-top: 20px;
}

.card_input {
  width: 80%;
  border: 1px solid var(--border-color);
  border-radius: 5px;
  height: 30px;
}

.card_date_cvv_wrap {
  display: flex;
  margin: 20px 0px;
}

.payment_select {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background: transparent;
  border: 2px solid #1d1a1acb;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.payment_selected {
  width: 12px;
  height: 12px;
  background-color: #1d1a1acb;
  border-radius: 50%;
}

/* --------------paypal------------ */
.paypal {
  width: 100%;
  display: flex;
  padding: 10px 5px;
  /* margin-bottom: 10px; */
  border: 1px solid var(--border-color);
}

.paypal_pay_with {
  display: flex;
  text-align: center;
  padding-left: 12%;
  margin-bottom: 20px;
}

/* --------------paypal------------ */

/* --------------Media Queries------------ */
@media (max-width: 976px) {
  .paypal_pay_with {
    padding-left: 10%;
  }
}

@media (max-width: 910px) {
  .paypal_pay_with {
    padding-left: 0%;
  }
}

@media (max-width: 780px) {
  .paypal_pay_with {
    font-size: 14px;
  }
}

@media (max-width: 700px) {
  .paypal_pay_with {
    font-size: 12px;
  }
}

@media (max-width: 600px) {
  .payment_container {
    flex-direction: column;
  }

  .payment_info_wrap {
    width: 100%;
  }

  .card_data_wrap {
    width: 100%;
    margin-top: 20px;
  }

  .paypal_pay_with {
    font-size: 16px;
    padding-left: 16%;
  }
}

@media (max-width: 512px) {
  .paypal_pay_with {
    padding-left: 14%;
  }
}

@media (max-width: 486px) {
  .paypal_pay_with {
    padding-left: 0%;
  }
}

@media (max-width: 390px) {
  .paypal_pay_with {
    font-size: 14px;
  }
}

@media (max-width: 359px) {
  .paypal_pay_with {
    font-size: 12px;
  }
}
/* --------------Media Queries------------ */
