.checkout {
  position: relative;
  width: 100%;
  max-width: 1400px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 70px auto 0px auto;
}

.checkout_container {
  width: 80%;
  margin: 0px auto;
  display: flex;
  justify-content: space-between;
  position: relative;
}

.checkout_shipping_info_wrap {
  width: 45%;
}

.checkout_cart_data_wrap {
  width: 45%;
}

.checkout_payment_submit {
  width: 150px;
  margin-top: 40px;
}

.checkout_shipping_info {
  width: 100%;
  background-color: #fff;
  border-radius: 10px;
  padding: 20px 20px 30px 20px;
}

.checkout_shipping_address {
  font-size: 18px;
  font-weight: 500;
}

.checkout_form_field {
  width: 80%;
  padding-bottom: 10px;
}

.checkout_form_field_2 {
  width: 80%;
  padding-bottom: 10px;
}

/* .checkout_full_name {
  width: 80%;
} */

.checkout_select {
  width: 95%;
  height: 40px;
  border-radius: 5px;
  border: 1px solid var(--border-color);
}

.checkout_option {
  display: block;
  padding-bottom: 10px;
}

.checkout_address_wrap {
  width: 100%;
  display: flex;
  padding-bottom: 10px;
}

.checkout_saved_address {
  font-size: 18px;
  cursor: pointer;
  inset-block: inherit;
}

.checkout_addresses {
  width: 100%;
  display: flex;
  margin-top: 5px;
}

.checkout_cart_data {
  width: 100%;
  background-color: #fff;
  border-radius: 10px;
  padding: 20px 20px 30px 20px;
}

/* --------------------- checkout steps --------------------- */
.checkout_steps {
  width: 100%;
  max-width: 1400px;
  display: flex;
  justify-content: center;
  margin: 70px auto 0px auto;
}

.checkout_steps_container {
  width: 60%;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-between;
}

.checkout_steps_active {
  width: 10%;
  border: 1px solid var(--pri-blue);
  color: var(--pri-blue);
  padding: 10px;
  background-color: #fff;
  border-radius: 5px;
}

.checkout_steps_unactive {
  width: 10%;
  background-color: #e9f3f7;
}
/* --------------------- checkout steps --------------------- */

/* --------------------- Media Queries --------------------- */
@media (max-width: 926px) {
  .checkout_form_field {
    width: 100%;
  }
}

@media (max-width: 870px) {
  .checkout_shipping_info_wrap {
    width: 50%;
  }
}

@media (max-width: 576px) {
  .checkout_container {
    flex-direction: column;
  }
  .checkout_shipping_info_wrap {
    width: 100%;
  }

  .checkout_cart_data_wrap {
    width: 100%;
    margin-top: 20px;
  }
}

@media (max-width: 480px) {
  .checkout_steps_container {
    width: 70%;
  }
}

@media (max-width: 480px) {
  .checkout_steps_container {
    width: 65%;
  }

  .checkout_steps_active {
    padding: 5px;
    font-size: 14px;
  }
  .checkout_steps_unactive {
    padding: 5px;
    font-size: 14px;
  }
}

@media (max-width: 360px) {
  .checkout_steps_container {
    width: 65%;
  }

  .checkout_steps_active {
    font-size: 12px;
  }
  .checkout_steps_unactive {
    font-size: 12px;
  }
}

/* --------------------- Media Queries --------------------- */
