.amlabs_hero {
  position: relative;
  width: 100%;
  height: 90vh;
  background-image: url("https://res.cloudinary.com/dcinefb4x/image/upload/v1706500974/am-labs-hero-img-2_ohfxqe.png");
  background-position: center;
  background-size: 100% 90vh;
}

/* .amlabs_hero_image {
  height: 100%;
  width: 100%;
} */

.amlabs_hero_container {
  position: absolute;
  top: 80px;
  left: 10%;
  width: 100%;
  max-width: 600px;
  z-index: 20;
}

.amlabs_hero_creators {
  position: absolute;
  left: 44%;
  font-size: 28px;
  color: #fff;
}

.amlabs_hero_title {
  font-size: 80px;
  width: 100%;
  color: yellow;
  font-weight: 700;
}

.amlabs_hero_text {
  color: white;
  font-size: 24px;
  margin-top: 40px;
}

.amlabs_hero_button {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  width: 120px;
  font-size: 24px;
  font-weight: 600;
  border-radius: 10px;
  position: absolute;
  bottom: 200px;
  left: 100px;
  padding: 0 20px;
  border: none;
  /* background: rgb(255, 123, 0); */
  background: rgba(255, 252, 36, 1);

  background: linear-gradient(
    0deg,
    /* rgba(255, 123, 0, 1) 0%, */ rgba(255, 252, 36, 1) 25%,
    rgba(255, 252, 36, 1) 75%,
    /* rgba(255, 123, 0, 1) 100% */
  );
}

.amlabs_hero_button:hover {
  border: 1px solid rgba(255, 252, 36, 1);
  background: black;
  color: rgba(255, 252, 36, 1);
  cursor: pointer;
}

.amlabs_email_container {
  position: absolute;
  left: 1%;
  top: 400px;
  width: 100%;
  max-width: 600px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  background-color: transparent;
  padding: 20px;
  box-shadow: 0px 5px 15px var(--pri-ylw);
  border-radius: 20px;
}

/* ------- animations ------------ */

.amlabs_hero span::before {
  content: "Reality";
  animation: animate infinite 8s;
}

@keyframes animate {
  0% {
    content: "Reality";
  }

  30% {
    content: "Web";
  }

  50% {
    content: "Apps";
  }

  75% {
    content: "Graphics";
  }
}

.gradient {
  font-size: 72px;
  background: -webkit-linear-gradient(
    rgba(255, 123, 0, 1) 0%,
    rgba(255, 252, 36, 1) 35%,
    rgba(255, 123, 0, 1) 100%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.flickerA {
  position: absolute;
  top: 200px;
  right: 100px;
  width: 15px;
  height: 15px;
  opacity: 15%;
  border-radius: 50%;
  box-shadow: 1px 1px 5px rgba(255, 252, 36, 1);
  background: -webkit-linear-gradient(
    rgba(255, 123, 0, 1) 0%,
    /* rgba(255, 252, 36, 1) 35%, */ rgba(255, 123, 0, 1) 100%
  );
}
.flickerB {
  position: absolute;
  top: 240px;
  right: 640px;
  width: 10px;
  height: 10px;
  opacity: 15%;
  border-radius: 50%;
  box-shadow: 1px 1px 5px rgba(255, 252, 36, 1);
  background: -webkit-linear-gradient(
    rgba(255, 123, 0, 1) 0%,
    /* rgba(255, 252, 36, 1) 35%, */ rgba(255, 123, 0, 1) 100%
  );
}
.flickerC {
  position: absolute;
  top: 130px;
  right: 365px;
  width: 10px;
  height: 10px;
  opacity: 15%;
  border-radius: 50%;
  box-shadow: 1px 1px 5px rgba(255, 252, 36, 1);
  background: -webkit-linear-gradient(
    rgba(255, 123, 0, 1) 0%,
    rgba(255, 252, 36, 1) 35%,
    rgba(255, 123, 0, 1) 100%
  );
}

.opacity {
  animation: pulse 5s infinite ease-in-out;
}

@keyframes pulse {
  0% {
    opacity: 10%;
  }

  25% {
    opacity: 20%;
  }

  50% {
    opacity: 80%;
  }

  75% {
    opacity: 20%;
  }
}

.flickerC {
  -webkit-animation: flickerC 6s infinite linear;
  animation: flickerC 6s infinite linear;
}

@-webkit-keyframes flickerC {
  from {
    -webkit-transform: rotateY(0deg) translateX(250px);
    transform: rotateY(0deg) translateX(250px);
  }
  to {
    -webkit-transform: rotateY(360deg) translateX(250px);
    transform: rotateY(360deg) translateX(250px);
  }
}

@keyframes flickerC {
  from {
    -webkit-transform: rotateY(0deg) translateX(250px);
    transform: rotateY(0deg) translateX(250px);
  }
  to {
    -webkit-transform: rotateY(360deg) translateX(250px);
    transform: rotateY(360deg) translateX(250px);
  }
}

/* ------- animations ------------ */

/* ------- Media Queries ------------ */

@media (max-width: 1184px) {
  .amlabs_hero_container {
    left: 6%;
  }
  .amlabs_hero_creators {
    left: 40%;
  }
}

@media (max-width: 1142px) {
  .amlabs_hero_container {
    left: 4%;
  }
}

@media (max-width: 1072px) {
  .amlabs_hero_container {
    left: 4%;
  }
  .amlabs_hero_title {
    font-size: 70px;
  }

  .amlabs_hero_text {
    color: white;
    font-size: 20px;
  }

  .amlabs_email_container {
    width: 90%;
  }
}

@media (max-width: 990px) {
  .amlabs_hero_title {
    font-size: 70px;
  }

  .amlabs_hero_text {
    color: white;
    font-size: 16px;
    width: 80%;
  }
}

@media (max-width: 936px) {
  .amlabs_hero_creators {
    left: 34%;
  }

  .amlabs_hero_title {
    font-size: 60px;
    width: 80%;
  }

  .amlabs_hero_text {
    color: white;
    font-size: 16px;
    width: 80%;
  }

  .amlabs_email_container {
    width: 80%;
    top: 360px;
  }

  .amlabs_grad {
    background-color: #00000099;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    z-index: 10;
  }

  .amlabs_hero {
    width: 100%;
    height: 90vh;
    background-image: url("https://res.cloudinary.com/dcinefb4x/image/upload/v1706815946/assets/am-labs-hero-img.psd-rev-2_rwpzkj.png");
    background-position: center;
    background-size: 160% 90vh;
  }
}

@media (max-width: 784px) {
  .amlabs_hero {
    background-size: 200% 90vh;
  }
}

@media (max-width: 684px) {
  .amlabs_hero {
    background-size: 240% 90vh;
  }

  .amlabs_hero_container {
    left: 5%;
    width: 90%;
    max-width: 600px;
    z-index: 20;
  }

  .gradient {
    font-size: 62px;
  }
}

@media (max-width: 588px) {
  .amlabs_hero_title {
    font-size: 56px;
  }
}

@media (max-width: 646px) {
  .amlabs_hero {
    background-size: 500% 90vh;
  }
  .amlabs_hero_creators {
    left: 28%;
  }
}

@media (max-width: 542px) {
  .amlabs_hero_creators {
    left: 24%;
  }

  .amlabs_email_container {
    width: 80%;
    top: 400px;
  }
  .amlabs_email_container input {
    width: 100%;
  }
}

@media (max-width: 476px) {
  .amlabs_hero_creators {
    left: 20%;
  }

  .amlabs_hero_container {
    display: flex;
    left: 5%;
    width: 90%;
    z-index: 20;
    justify-content: center;
  }

  .amlabs_hero_title {
    position: absolute;
    font-size: 56px;
    width: 75%;
    text-align: center;
  }

  .amlabs_hero_text {
    color: white;
    font-size: 15px;
    width: 65%;
    text-align: center;
    margin-top: 280px;
  }

  .amlabs_email_container {
    left: 10%;
    top: 440px;
  }

  .gradient {
    font-size: 62px;
  }
}

@media (max-width: 476px) {
  .amlabs_hero_creators {
    left: 24%;
    font-size: 22px;
  }
}

@media (max-width: 384px) {
  .amlabs_hero_creators {
    left: 23%;
  }

  .amlabs_hero_title {
    position: absolute;
    font-size: 52px;
    width: 80%;
    text-align: center;
  }
}

/* @media (max-width: 436px) {

  .amlabs_hero_title {
    position: absolute;
    font-size: 50px;
    width: 70%;
    text-align: center;
    left: -2%;
  }

  .amlabs_hero_text {
    color: white;
    font-size: 15px;
    width: 65%;
    text-align: center;
    margin-top: 220px;
  }

  .amlabs_email_container {
    width: 60%;
    left: 3%;
  }

  .gradient {
    font-size: 62px;
  }
} */
/* ------- Media Queries ------------ */
