.popular_post {
  width: 100%;
  display: flex;
  height: fit-content;
  flex-direction: column;
}

.popular_post_container {
  position: relative;
  display: flex;
  justify-content: space-between;
  flex-wrap: nowrap;
  width: 100%;
  max-width: 1400px;
  margin-bottom: 100px;
}

.popular_post_card {
  width: 25%;
  height: 440px;
  background-color: #fff;
  position: relative;
  margin-right: 15px;
  box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.1);
}

.popular_post_card:nth-child(4) {
  margin-right: 0;
}

.popular_post_header {
  position: relative;
  padding: 10px 10px 0px 10px;
}

.popular_post_header_info {
  display: flex;
  width: 100%;
  position: relative;
}

.popular_post_header_left {
  width: 100%;
  display: flex;
}

.popular_post_picture {
  width: 30px;
  height: 30px;
  border-radius: 50%;
}

.popular_post_username {
  margin-left: 10px;
  font-size: 14px;
}

.popular_post_sponsored {
  position: absolute;
  top: 80px;
  right: 10px;
  font-size: 10px;
  color: #fff;
  z-index: 3;
}

.popular_post_text {
  font-size: 12px;
  padding: 15px 10px 15px 10px;
  color: gray;
}

.popular_post_main_image {
  position: absolute;
  width: 100%;
  height: 81%;
  bottom: 0;
}

.popular_post_bottom {
  position: relative;
  display: flex;
  padding: 10px;
}

.popular_post_likes {
  position: absolute;
  top: 26px;
  left: 90px;
  color: gray;
  font-size: 12px;
}

/* --------------------media queries-------------- */
@media (max-width: 1304px) {
  .popular_post_card {
    height: 430px;
  }
}

@media (max-width: 1264px) {
  .popular_post_card {
    height: 420px;
  }
}

@media (max-width: 1174px) {
  .popular_post_card {
    height: 380px;
  }
  .popular_post_text {
    padding: 0px 10px 35px 10px;
  }
  .popular_post_sponsored {
    top: 70px;
  }
}

@media (max-width: 992px) {
  .popular_post_main_image {
    height: 300px;
    object-fit: cover;
    bottom: 0;
  }
}

@media (max-width: 968px) {
  .popular_post_card {
    height: 350px;
  }
  .popular_post_sponsored {
    top: 70px;
  }
  .popular_post_main_image {
    height: 270px;
  }
}

@media (max-width: 930px) {
  .popular_post_card {
    height: 330px;
  }
  .popular_post_main_image {
    height: 250px;
  }
}

@media (max-width: 738px) {
  .popular_post_card {
    height: 300px;
  }
  .popular_post_main_image {
    height: 240px;
  }
}

@media (max-width: 680px) {
  .popular_post_text {
    font-size: 10px;
  }
  .popular_post_main_image {
    height: 220px;
  }
}

@media (max-width: 632px) {
  .popular_post_card {
    height: 300px;
    margin-right: 10px;
  }
}

@media (max-width: 608px) {
  .popular_post {
    margin-top: 20px;
  }
  .popular_post_container {
    flex-wrap: wrap;
    justify-content: flex-start;
  }
  .popular_post_card {
    width: 45%;
    height: 340px;
    margin-right: 20px;
    margin-bottom: 20px;
    left: 15px;
  }

  .popular_post_text {
    font-size: 12px;
    padding: 5px 10px 15px 10px;
    color: gray;
  }

  .popular_post_main_image {
    position: absolute;
    width: 100%;
    height: 260px;
    bottom: 0;
  }
}

@media (max-width: 472px) {
  .popular_post_card {
    left: 10px;
  }
}

/* @media (max-width: 426px) {
  .popular_post {
    margin-top: 20px;
  }
} */

@media (max-width: 414px) {
  .popular_post_card {
    width: 46%;
    height: 340px;
    margin-right: 12px;
    margin-bottom: 15px;
    left: 10px;
  }
}

/* --------------------media queries-------------- */
