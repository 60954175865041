.hero_carousel {
  display: flex;
  height: 420px;
  width: 100%;
  /* max-width: 800px; */
}

.hero_carousel_wrapper {
  position: relative;
  width: 100%;
  height: 100%;
}

.hero_carousel_card {
  display: flex;
  flex: 1;
  position: absolute;
  width: 100%;
  height: 100%;
  overflow: hidden;
  /* border-radius: 20px; */
  /* -webkit-box-shadow: 0px 0px 9px 1px rgba(0, 0, 0, 0.1); */
  /* box-shadow: 0px 0px 9px 1px rgba(0, 0, 0, 0.1); */
  opacity: 0;
  pointer-events: none;
  transform: scale(0.8);
  transition: 0.7s ease-in-out;
}

.hero_carousel_card-active {
  opacity: 1;
  transform: scale(1);
  pointer-events: visible;
}

.hero_carousel_card_image {
  width: 100%;
  /* object-fit: cover; */
}

.hero_carousel_card_overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  padding: 40px 30px;
  align-items: flex-end;
}

.hero_carousel_card_name {
  color: #fff;
  font-size: 30px;
}

.hero_carousel_arrow_left,
.hero_carousel_arrow_right {
  position: absolute;
  font-size: 40px;
  top: 50%;
  transform: translate(0, -50%);
  background-color: gainsboro;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  padding-bottom: 7px;
  cursor: pointer;
}

.hero_carousel_arrow_left {
  left: 15px;
}

.hero_carousel_arrow_right {
  right: 15px;
}

.hero_carousel_pagination {
  position: absolute;
  bottom: 18px;
  left: 50%;
  transform: translate(-50%, 0);
}

.pagination_dot {
  height: 10px;
  width: 10px;
  background-color: #dedede;
  /* background-color: #fff; */
  border-radius: 50%;
  display: inline-block;
  margin-left: 10px;
  cursor: pointer;
}

.pagination_dot:hover {
  transform: scale(1.2);
}

.pagination_dot-active {
  background-color: steelblue;
}
