.product_card {
  display: flex;
  flex-direction: column;
  width: 240px;
  height: 360px;
  border-radius: 10px;
  box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.1);
  /* padding: 3px; */
  position: relative;
  /* cursor: pointer; */
  background: #fff;
  margin: 20px;
}

.product_name {
  display: flex;
  justify-content: flex-end;
}

.product_shop_name {
  font-size: 14px;
  font-weight: 300;
  color: var(--blue-color);
  margin-bottom: 5px;
}

.product_image {
  width: 100%;
  height: 170px;
  object-fit: contain;
  margin-bottom: 10px;
}

.product_name_slice {
  padding-bottom: 3px;
  font-weight: 300;
  font-size: 16px;
}

.product_description {
  font-size: 1rem;
  font-weight: 400;
  color: gray;
}

.product_info_wrap {
  position: absolute;
  bottom: 10px;
}
.product_rating {
  display: flex;
  align-items: center;
  margin-top: 10px;
  justify-content: space-between;
  width: 230px;
}

.product_star {
  margin-right: 2px;
  cursor: pointer;
  margin: 10px 0px;
}

.product_icon {
  cursor: pointer;
  position: absolute;
  right: 8px;
  top: 20px;
}

.product_price {
  padding: 0px 4px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-decoration: line-through;
}

.product_sold {
  color: #68d284;
  font-weight: 400;
  font-size: 1rem;

  /* position: absolute;
  right: 0px; */
}

.product_info {
  display: flex;
  padding: 0 8px;
  margin-top: 10px;
  align-items: center;
  justify-content: space-between;
}

.product_heart_icon {
  cursor: pointer;
  position: absolute;
  right: 8px;
  top: 8px;
  padding: 2px;
  border-radius: 50%;
  background-color: #fff;
}

.product_eye_icon {
  cursor: pointer;
  position: absolute;
  right: 8px;
  top: 38px;
  padding: 2px;
  border-radius: 50%;
  background-color: #fff;
}

.product_cart_icon {
  cursor: pointer;
  position: absolute;
  right: 8px;
  top: 66px;
  padding: 2px;
  border-radius: 50%;
  background-color: #ffffff;
}

/* -----------------product detail card ---------------- */

.pdc_container {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 48px;
  left: 0;
  background: #ffffff95;
  z-index: 800;
  display: flex;
  align-items: center;
  justify-content: center;
}

.pdc_wrap {
  position: relative;
  width: 60%;
  height: 70vh;
  overflow-y: scroll;
  background: #fff;
  border-radius: 20px;
  box-shadow: 0px 5px 15px gray;
  padding: 20px;
  display: flex;
  justify-content: space-between;
}

.pdc_cross {
  position: absolute;
  right: 20px;
  top: 12px;
  z-index: 850;
}

/* .pdc_item {
  display: flex;
  width: 100%;
} */

.pdc_wrap_left {
  position: relative;
  width: 45%;
  padding: 20px 0px;
}

.pdc_wrap_right {
  position: relative;
  width: 45%;
  margin-top: 20px;
}

.pdc_image {
  height: 480px;
  width: 100%;
  max-width: 480px;
}

.pdc_shop_avatar {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin-right: 4px;
}

.pdc_ratings {
  padding-bottom: 12px;
  font-size: 14px;
  color: gray;
  margin-left: 10px;
}

.pdc_message {
  background-color: #000;
  width: 160px;
  font-size: 16px;
  color: #fff;
  margin-top: 10px;
  border-radius: 5px;
  height: 46px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.pdc_soldout {
  font-size: 16px;
  color: red;
  margin-top: 20px;
  margin-left: 20px;
}

.pdc_shop_name {
  font-size: 16px;
  color: var(--blue-color);
}

/* .pdc_name {
  width: 80%;
  padding: 20px 20px 0px 20px;
} */

.pdc_description {
  font-size: 18px !important;
  color: gray !important;
  font-weight: 300;
  margin-top: 20px;
}

.pdc_price_wrap {
  display: flex;
  padding-top: 12px;
}

.pdc_count {
  color: gray;
  font-weight: 400;
  padding: 10px 20px;
}

.pdc_actions {
  display: flex;
  position: relative;
}

.pdc_actions svg {
  margin-top: 5px;
  margin-left: 15px;
}

.pdc_actions_buttons {
  display: flex;
}

/* .pdc_message {
  margin-left: 20px;
} */

.pdc_decremement_button {
  color: #fff;
  font-weight: 600;
  font-size: 18px;

  padding: 10px 16px;
  border-radius: 5px;
  box-shadow: 0px 5px 15px lightblue;
  transition: cubic-bezier(0.075, 0.82, 0.165, 1);
  border: none;
  cursor: pointer;
  background: rgb(94, 151, 183);
  background: linear-gradient(
    95deg,
    rgba(94, 151, 183, 1) 31%,
    rgba(94, 151, 183, 0.6895892145920868) 74%
  );
}

.pdc_increment_button {
  font-size: 18px;
  color: #fff;
  font-weight: 600;
  padding: 10px 16px;
  border-radius: 5px;
  box-shadow: 0px 5px 15px lightblue;
  transition: cubic-bezier(0.075, 0.82, 0.165, 1);
  border: none;
  cursor: pointer;
  background: rgb(94, 151, 183);
  background: linear-gradient(
    263deg,
    rgba(94, 151, 183, 1) 31%,
    rgba(94, 151, 183, 0.6895892145920868) 74%
  );
}

.pdc_discount_price {
  font-size: 24px;
  font-weight: 400;
  color: var(--pri-blue);
  text-decoration: line-through;
  padding-left: 20px;
}

.pdc_cart_button {
  margin-top: 20px;
  border-radius: 5px;
  width: 200px;
  height: 40px;
  background-color: red;
  font-size: 20px;
  font-weight: 400;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

/* ----------------------- Tr Product Card ---------------------------- */

.tr_product_card_container {
  display: flex;
  flex-wrap: nowrap;
  width: 100%;
  height: 300px;
  max-width: 1200px;
  margin: 60px auto 0px auto;
  /* justify-content: space-between; */
  /* background-color: red; */
}

.tr_product_card {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 18%;
  height: 260px;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 5px 10px 15px var(--shadow-2);
  margin: 0px 10px;
}

.tr_product_card_image {
  width: 100%;
  height: 160px;
  object-fit: contain;
}

.tr_product_name_slice {
  padding-bottom: 3px;
  font-weight: 300;
  font-size: 18px;
  color: var(--color-primary);
  padding: 5px;
}

.tr_product_info_wrap {
  display: flex;
  margin-top: 6px;
}

.tr_product_info {
  display: flex;
  padding: 0 8px;
  /* margin-top: 6px; */
  align-items: center;
  justify-content: space-between;
}

/* ----------------------- Tr Product Card ---------------------------- */

/* -------------- media queries -------------------- */
@media (max-width: 1400px) {
  .product_card {
    margin: 15px 10px;
    width: 220px;
    height: 340px;
  }
  .product_rating {
    width: 210px;
  }
  .pdc_wrap {
    width: 90%;
  }
  .pdc_wrap_left {
    width: 50%;
  }
}

@media (max-width: 1264px) {
  .product_card {
    width: 210px;
    margin: 15px 8px;
  }
  .product_rating {
    width: 200px;
  }
}

@media (max-width: 1188px) {
  .product_card {
    width: 200px;
    margin: 15px 6px;
  }
  .product_rating {
    width: 190px;
  }
}

@media (max-width: 1160px) {
  .pdc_image {
    height: 440px;
  }
  .product_sold {
    display: none;
  }
}

@media (max-width: 1128px) {
  .product_card {
    width: 190px;
    height: 320px;
    margin: 15px 6px;
  }
  .product_image {
    margin-bottom: 0px;
  }
}

@media (max-width: 1062px) {
  .product_card {
    width: 180px;
    margin: 15px 6px;
  }
  .pdc_image {
    height: 400px;
  }
}

@media (max-width: 1102px) {
  .tr_product_name_slice {
    font-size: 16px;
  }
}

@media (max-width: 988px) {
  .tr_product_name_slice {
    font-size: 14px;
  }
}

@media (max-width: 888px) {
  .tr_product_card {
    height: 240px;
  }

  .tr_product_name_slice {
    font-size: 12px;
  }
}

@media (max-width: 812px) {
  .product_card {
    width: 170px;
  }
  .pdc_image {
    height: 360px;
  }
  .pdc_description {
    font-size: 14px !important;
  }
}

@media (max-width: 800px) {
  .tr_product_card_container {
    justify-content: center;
  }

  .tr_product_card {
    width: 22%;
    /* margin: 0px 15px; */
  }

  .tr_product_card:nth-child(4) {
    display: none;
  }
}

@media (max-width: 766px) {
  .product_card {
    width: 160px;
    height: 290px;
  }

  .product_name_slice {
    font-size: 14px;
  }

  .product_rating {
    display: none;
  }
}

@media (max-width: 683px) {
  .product_card {
    width: 140px;
  }
  .product_name_slice {
    font-size: 12px;
  }
  .pdc_image {
    height: 300px;
  }

  .tr_product_card {
    height: 230px;
  }

  .tr_product_info {
    margin-top: 0px;
  }
}

@media (max-width: 644px) {
  .product_card {
    width: 135px;
  }

  /* .tr_product_card {
    width: 22%;
    height: 240px;
  } */

  .tr_product_name_slice {
    font-size: 11px;
  }
}

@media (max-width: 619px) {
  .product_card {
    width: 130px;
  }
}

@media (max-width: 608px) {
  .product_card {
    width: 260px;
    margin: 15px 10px;
  }

  .product_name_slice {
    font-size: 16px;
  }
  .pdc_image {
    height: 260px;
  }
}

@media (max-width: 590px) {
  .product_card {
    width: 250px;
    /* margin: 15px 10px; */
  }
}

@media (max-width: 580px) {
  .tr_product_card {
    height: 210px;
  }

  .tr_product_card_image {
    height: 140px;
    object-fit: contain;
  }

  .tr_product_info_wrap {
    display: flex;
    margin-top: 2px;
  }

  .tr_product_name_slice {
    font-size: 10px;
  }
}

@media (max-width: 568px) {
  .product_card {
    width: 245px;
    /* margin: 15px 10px; */
  }
}

@media (max-width: 560px) {
  .product_card {
    width: 240px;
    /* margin: 15px 10px; */
  }
}

@media (max-width: 548px) {
  .product_card {
    width: 220px;
    /* margin: 15px 10px; */
  }

  .tr_product_card {
    height: 190px;
    margin: 0 8px;
  }

  .tr_product_card_image {
    height: 120px;
    object-fit: contain;
  }
}

@media (max-width: 520px) {
  .tr_product_card_container {
    flex-wrap: wrap;
    height: 570px;
  }
  .tr_product_card {
    width: 40%;
    height: 260px;
    margin: 0px 15px;
  }

  .tr_product_card_image {
    width: 100%;
    height: 160px;
  }

  .tr_product_name_slice {
    padding-bottom: 3px;
    font-size: 18px;
    padding: 5px;
  }

  .tr_product_info_wrap {
    display: flex;
    margin-top: 6px;
  }

  .tr_product_info {
    display: flex;
    padding: 0 8px;
    /* margin-top: 6px; */
    align-items: center;
    justify-content: space-between;
  }
}

@media (max-width: 506px) {
  .product_card {
    width: 210px;
    margin: 15px 10px;
  }
  .product_description {
    font-size: 0.9rem;
  }
  .product_sold {
    font-size: 0.9rem;
  }
  .pdc_wrap {
    height: 80vh;
    width: 95%;
  }
  .pdc_image {
    height: 220px;
  }
  .pdc_cart_button {
    width: 160px !important;
  }
}

@media (max-width: 484px) {
  .product_card {
    width: 200px;
    margin: 10px 10px;
  }

  .pdc_wrap_left {
    width: 45%;
  }

  .pdc_wrap_right {
    width: 50%;
  }

  .pdc_image {
    height: 180px;
  }

  .tr_product_name_slice {
    padding-bottom: 3px;
    font-size: 16px;
    padding: 5px;
  }

  .tr_product_card {
    height: 250px;
  }
}

@media (max-width: 464px) {
  .product_card {
    width: 190px;
    margin: 15px 6px;
  }
}

@media (max-width: 426px) {
  .product_card {
    width: 180px;
    height: 280px;
    margin: 10px 6px;
  }
  .product_description {
    display: none;
  }
  .product_info {
    position: absolute;
    bottom: -8px;
  }
  .product_star {
    margin-right: 2px;
    cursor: pointer;
    margin: 0px 0px 6px 0px;
  }

  .tr_product_name_slice {
    padding-bottom: 3px;
    font-size: 14px;
    padding: 5px;
  }
}

@media (max-width: 404px) {
  .product_card {
    width: 160px;
  }
  .product_name_slice {
    font-size: 12px;
  }
  .product_description {
    display: none;
  }
  .pdc_decremement_button {
    padding: 6px 12px;
  }
  .pdc_increment_button {
    padding: 6px 10px;
  }
  .pdc_count {
    padding: 10px 14px;
  }
}

@media (max-width: 386px) {
  .tr_product_name_slice {
    font-size: 13px;
  }
}

@media (max-width: 362px) {
  .product_card {
    width: 155px;
  }
  .product_description {
    display: none;
  }
}

/* -------------- media queries -------------------- */
