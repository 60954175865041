.categories {
  width: 95%;
  max-width: 1400px;
  height: 240px;
  display: flex;
  /* background: #fff; */
  margin: 0px auto 0px auto;
  /* border-radius: 10px; */
  /* box-shadow: 5px 10px 15px var(--shadow-2); */
}

.categories_container {
  display: flex;
  flex-wrap: wrap;
  position: relative;
  width: 100%;
  max-width: 1280px;
  margin: 0 auto;
  justify-content: space-between;
  align-items: center;
}

.categories_item {
  width: 15%;
  height: 85%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  overflow: hidden;
  flex-direction: column;
}

.categories_image {
  /* height: 80px; */
  width: 99%;
  height: 176px;
  border-radius: 50%;
  object-fit: cover;
  /* margin-right: 10px; */
}

.categories_image_wrap {
  background-color: #fff;
  width: 99%;
  height: 180px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  border: 2px solid var(--pri-ylw);
}

.categories_text {
  margin-top: 5px;
  font-size: 16px;
}

/* -------------------------- home categories --------------------------- */
.home_categories {
  margin-top: 60px;
}

.home_categories_container {
  /* width: 1020px; */
  width: 100%;
  display: flex;
  justify-content: space-between;
  position: relative;
}

.home_categories_card {
  position: relative;
  display: flex;
  flex-direction: column;
  /* width: 340px; */
  width: 25%;
  height: 440px;
  background-color: #fff;
  padding: 10px;
  margin-right: 15px;
  box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.1);
}

.home_categories_title {
  position: absolute;
  font-size: 28px;
  font-weight: 500;
  width: 100%;
  height: 40px;
}

.home_categories_subTitle {
  position: absolute;
  bottom: 10px;
  left: 20px;
  color: var(--pri-blue);
  font-size: 18px;
}

.home_categories_images {
  position: absolute;
  top: 70px;
  left: 8px;
  display: flex;
  height: 310px;
  width: 94%;
}

.home_categories_image {
  width: 100%;
  height: 80%;
  margin-top: 10px;
  cursor: pointer;
}

.home_categories_text {
  position: absolute;
  font-size: 1rem;
  bottom: 0px;
  left: 0;
}

.home_category_wrap_0 {
  position: absolute;
  height: 150px;
  width: 44%;
  top: 0px;
  left: 0px;
}

.home_category_wrap_1 {
  position: absolute;
  height: 150px;
  width: 43%;
  top: 0px;
  right: 0px;
}

.home_category_wrap_2 {
  position: absolute;
  height: 150px;
  width: 43%;
  bottom: 0px;
  /* left: 10px; */
}

.home_category_wrap_3 {
  position: absolute;
  height: 150px;
  width: 43%;
  bottom: 0px;
  right: 0px;
}

/* Share */
.home_categories_share_wrap {
  position: relative;
  background-color: #fff;
  /* width: 330px; */
  width: 25%;
  padding: 10px;
  box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.1);
}

.home_categories_share_image {
  position: absolute;
  top: 78px;
  left: 10px;
  /* width: 310px; */
  width: 94%;
  height: 300px;
  cursor: pointer;
}

.home_categories_share_subTitle {
  position: absolute;
  bottom: 10px;
  left: 10px;
  color: var(--pri-blue);
  font-size: 18px;
}

/* -------------------------- home categories --------------------------- */
/* -------------------------- truly remembered categories --------------------------- */

.tr_categories {
  width: 95%;
  max-width: 1400px;
  height: 200px;
  display: flex;
  margin: -20px auto 0px auto;
}

.tr_categories_container {
  display: flex;
  flex-wrap: wrap;
  position: relative;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  /* justify-content: space-between; */
  align-items: center;
  justify-content: center;
}

.tr_categories_item {
  width: 15%;
  height: 85%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  overflow: hidden;
  flex-direction: column;
  margin: 0 5px;
}

.tr_categories_image {
  /* height: 80px; */
  width: 99%;
  height: 110px;
  border-radius: 50%;
  object-fit: cover;
  /* margin-right: 10px; */
}

.tr_categories_image_wrap {
  background-color: #fff;
  width: 92%;
  height: 120px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  box-shadow: 5px 10px 15px var(--shadow-2);

  border: 2px solid var(--border-color);
}

.tr_categories_text {
  margin-top: 5px;
  font-size: 16px;
}

/* -------------------------- truly remembered categories --------------------------- */

/* -------------------- Media Queries ------------------------ */

@media (max-width: 1400px) {
  .home_categories_images {
    height: 300px;
  }
}

@media (max-width: 1340px) {
  .home_categories_images {
    /* background-color: red; */
    left: 8px;
  }

  .home_category_wrap_0 {
    height: 140px;
  }

  .home_category_wrap_1 {
    height: 140px;
  }

  .home_category_wrap_2 {
    height: 140px;
  }

  .home_category_wrap_3 {
    height: 140px;
  }

  .home_categories_share_image {
    height: 270px;
  }

  .home_categories_subTitle {
    left: 10px;
  }
}

@media (max-width: 1304px) {
  .home_categories_card {
    height: 430px;
  }

  .home_categories_text {
    font-size: 0.9rem;
  }

  .home_categories_share_image {
    height: 270px;
  }
}

@media (max-width: 1264px) {
  /* .categories_item {
    width: 14%;
    height: 85%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    overflow: hidden;
    flex-direction: column;
    background-color: blue;
  } */
  .categories_image_wrap {
    width: 99%;
    height: 164px;
  }

  .categories_image {
    /* height: 80px; */
    width: 100%;
    height: 164px;
    border-radius: 50%;
    object-fit: cover;
    /* margin-right: 10px; */
  }

  .categories_text {
    font-size: 15px;
  }

  .home_categories_card {
    height: 420px;
  }

  .home_categories_images {
    height: 280px;
  }
  .home_categories_share_image {
    height: 255px;
  }
}

@media (max-width: 1174px) {
  /* .categories_text {
    font-size: 14px;
  } */
  .home_categories_card {
    height: 380px;
  }

  .home_categories_images {
    height: 260px;
    top: 60px;
  }

  .home_category_wrap_0 {
    height: 125px;
  }

  .home_category_wrap_1 {
    height: 125px;
  }

  .home_category_wrap_2 {
    height: 125px;
  }

  .home_category_wrap_3 {
    height: 125px;
  }

  .home_categories_text {
    font-size: 0.7rem;
  }
  .home_categories_share_image {
    height: 230px;
    width: 90%;
    top: 70px;
  }
}

@media (max-width: 1128px) {
  .categories_image_wrap {
    height: 150px;
  }

  .categories_image {
    height: 145px;
  }

  .home_categories {
    margin-top: 20px;
  }
}
@media (max-width: 1028px) {
  .home_categories_title {
    font-size: 1.6rem;
  }

  .home_categories_images {
    left: 5px;
    height: 250px;
  }

  .home_categories_text {
    font-size: 0.7rem;
  }

  .home_categories_share_image {
    top: 70px;
    height: 220px;
  }
}

@media (max-width: 992px) {
  .categories_image_wrap {
    height: 140px;
  }

  .categories_image {
    height: 135px;
  }
}

@media (max-width: 968px) {
  /* .categories_text {
    font-size: 13px;
  } */
  .categories_image_wrap {
    height: 130px;
  }

  .categories_image {
    height: 125px;
  }

  .home_categories_card {
    height: 350px;
  }

  .home_categories_title {
    font-size: 1.5rem;
  }

  .home_categories_image {
    height: 90px;
  }

  .home_categories_images {
    height: 230px;
  }

  .home_categories_text {
    font-size: 0.6rem;
  }

  .home_category_wrap_0 {
    height: 115px;
  }

  .home_category_wrap_1 {
    height: 115px;
  }

  .home_category_wrap_2 {
    height: 115px;
  }

  .home_category_wrap_3 {
    height: 115px;
  }

  .home_categories_share_image {
    top: 70px;
    height: 210px;
  }

  /* .home_categories_images {
    left: 0px;
    height: 250px;
    width: 100%;
  } */
}

@media (max-width: 930px) {
  .home_categories_card {
    height: 330px;
  }

  .home_categories_images {
    height: 220px;
    top: 50px;
  }

  .home_categories_title {
    font-size: 1.2rem;
    height: 30px;
  }
  .home_categories_image {
    height: 80px;
    width: 100%;
  }
  .home_category_wrap_0 {
    height: 105px;
    width: 48%;
  }

  .home_category_wrap_1 {
    height: 105px;
    width: 48%;
  }

  .home_category_wrap_2 {
    height: 105px;
    width: 48%;
  }

  .home_category_wrap_3 {
    height: 105px;
    width: 48%;
  }

  /* .home_categories_share_wrap {
    width: 350px;
  } */

  .home_categories_share_image {
    top: 60px;
    height: 195px;
  }

  .home_categories_subTitle {
    left: 5px;
    font-size: 16px;
  }
  .home_categories_share_subTitle {
    font-size: 16px;
  }
}

@media (max-width: 968px) {
  .categories_image_wrap {
    height: 115px;
  }

  .categories_image {
    height: 110px;
  }
}

@media (max-width: 844px) {
  .categories_image_wrap {
    height: 105px;
  }

  .categories_image {
    height: 100px;
  }
}

@media (max-width: 810px) {
  .home_categories {
    margin-top: 60px;
  }
}

@media (max-width: 738px) {
  .categories_item {
    width: 180px;
    height: 56%;
  }

  .categories_image_wrap {
    height: 105px;
    width: 105px;
  }

  .categories_image {
    height: 100px;
  }

  .home_categories_card {
    height: 310px;
  }

  .home_categories_title {
    font-size: 1.1rem;
    left: 5px;
  }

  .home_categories_images {
    height: 220px;
    top: 40px;
  }
  .home_categories_text {
    font-size: 8.5px;
  }

  .home_categories_share_image {
    top: 50px;
    left: 8px;
  }
}

@media (max-width: 672px) {
  .home_categories_text {
    font-size: 8px;
  }
}

@media (max-width: 632px) {
  .home_categories_card {
    height: 300px;
    margin-right: 10px;
  }

  .home_categories_title {
    font-size: 1rem;
  }

  .home_categories_text {
    font-size: 0.5rem;
  }
}

@media (max-width: 608px) {
  .home_categories_container {
    flex-wrap: wrap;
    justify-content: flex-start;
  }

  .home_categories_card {
    width: 45%;
    height: 340px;
    padding: 10px;
    margin-right: 20px;
    margin-bottom: 20px;
    left: 15px;
  }

  .home_categories_title {
    font-size: 1.5rem;
  }

  .home_categories_images {
    height: 220px;
    width: 88%;
    top: 50px;
    left: 15px;
  }

  .home_categories_text {
    font-size: 0.7rem;
  }

  .home_categories_share_wrap {
    width: 45%;
    height: 340px;
    margin-left: 10px;
  }

  .home_categories_share_image {
    top: 62px;
    left: 10px;
    height: 195px;
  }
}

@media (max-width: 576px) {
  .home_categories {
    margin-top: 120px;
  }

  .categories_item {
    width: 160px;
  }
}

@media (max-width: 524px) {
  .home_categories_images {
    width: 90%;
    top: 50px;
    left: 10px;
  }
}

@media (max-width: 506px) {
  .categories_item {
    width: 140px;
  }
}
@media (max-width: 472px) {
  .home_categories_card {
    left: 10px;
  }

  .home_categories_text {
    font-size: 0.6rem;
  }
}

@media (max-width: 444px) {
  .categories_item {
    width: 120px;
  }

  .categories_image_wrap {
    height: 105px;
    width: 105px;
  }

  .categories_image {
    height: 100px;
  }
}
@media (max-width: 430px) {
  .home_categories_title {
    font-size: 1.4rem;
  }

  .home_categories_images {
    width: 95%;
    top: 50px;
    left: 5px;
  }

  .home_categories_text {
    font-size: 0.6rem;
  }
}

@media (max-width: 414px) {
  .home_categories_title {
    font-size: 1.2rem;
  }

  .home_categories_card {
    width: 46%;
    height: 340px;
    padding: 10px;
    margin-right: 12px;
    margin-bottom: 15px;
    left: 5px;
  }

  .home_categories_images {
    left: 3px;
  }

  .home_categories_share_wrap {
    width: 46%;
    height: 340px;
    margin-left: 5px;
  }
}

@media (max-width: 380px) {
  .categories_item {
    width: 100px;
  }

  .categories_image_wrap {
    height: 95px;
    width: 95px;
  }

  .categories_image {
    height: 90px;
  }
}
@media (max-width: 370px) {
  .home_categories_text {
    font-size: 9px;
  }
}

@media (max-width: 334px) {
  .home_categories_text {
    font-size: 8px;
  }
}

/* -------------------- Media Queries ------------------------ */
