.db_header {
  display: flex;
  width: 100%;
  height: 49px;
  background: #fff;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 30;
  align-items: center;
  justify-content: space-between;
  padding: 0 15px;
  box-shadow: 1px 8px 15px -7px var(--shadow-2);
}

.shop_info_buttons {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

/* ------------------- Shop Info ------------------- */
.shop_info_left {
  width: 20%;
  height: 100vh;
  background-color: #fff;
  padding: 60px 20px;
}
.shop_info_left_data {
  margin-top: 49px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.shop_info_image {
  width: 150px;
  height: 150px;
  border-radius: 50%;
  object-fit: cover;
}

.shop_info_name {
  text-align: center;
  padding: 0px 10px;
  font-size: 20px;
}

.shop_info_description {
  font-size: 16px;
  color: #000000a6;
  padding: 10px;
  display: flex;
  align-items: center;
}
/* ------------------- Shop Info ------------------- */

/* ----------------------- Create Product -------------------------------- */
.create_product {
  width: 100%;
  max-width: 1400px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.createProduct {
  background: #fff;
  border-radius: 10px;
  box-shadow: 0 1px 2px var(--shadow-1);
  margin: 1.1rem auto;
  height: fit-content;
  margin-bottom: 1.1rem;
  width: 60%;
  max-width: 800px;
  position: absolute;
  left: 30%;
  top: 70px;
}

.createProduct_header {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  padding: 10px 17px 5px 15px;
  font-size: 20px;
}

.create_splitter {
  height: 1px;
  width: 95%;
  background: var(--bg-third);
  margin: 7px 10px;
}

.createProduct_body {
  padding: 0 10px 8px 10px;
}

.createPost_icon {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  font-weight: 600;
  padding: 7px;
  color: var(--color-secondary);
  border-radius: 10px;
  font-size: 14px;
}

.create_product_actions {
  width: 100%;
  height: 80px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.create_product_input_wrap {
  display: flex;
  flex-direction: column;
}

.create_product_input {
  margin-top: 8px;
  appearance: none;
  height: 35px;
  border: 1px solid var(--border-color);
  border-radius: 5px;
  padding: 0 12px;
}

.create_product_input:focus {
  display: block;
  border: 1px solid var(--pri-blue);
}

.create_product_add_image {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.create_product_image {
  height: 120px;
  width: 120px;
  object-fit: cover;
  margin: 8px;
}

/* ----------------------- Create Product -------------------------------- */

/* ----------------------- Create Auction Product -------------------------------- */
.au_product_checkbox_container {
  display: flex;
  justify-content: space-around;
  width: 100%;
  border-radius: 10px;
  border: 1px solid lightgray;
  padding-bottom: 10px;
  margin: 8px auto 20px auto;
}

/* ----------------------- Create Auction Product -------------------------------- */

/* -------------------- All Products -------------------- */
.all_products {
  width: 100%;
  max-width: 1400px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.all_products_container {
  position: absolute;
  left: 30%;
  top: 70px;
  width: 60%;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 1px 2px var(--shadow-1);
  margin: 1.1rem auto;
}

/* -------------------- All Products -------------------- */

/* ----------------------- Create Product -------------------------------- */
.dashboard_message {
  width: 80%;
  position: relative;
}

.dashboard_message_container {
  background: #fff;
  border-radius: 10px;
  box-shadow: 0 1px 2px var(--shadow-1);
  margin: 1.1rem auto;
  height: fit-content;
  margin-bottom: 1.1rem;
  width: 100%;
  max-width: 800px;
  position: absolute;
  left: 500px;
  top: 70px;
  overflow-y: scroll;
}

.dashboard_message_header {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  padding: 10px 17px 5px 15px;
  font-size: 20px;
}

.dashboard_message_splitter {
  height: 1px;
  width: 95%;
  background: var(--bg-third);
  margin: 7px 10px;
}
.dashboard_message_body {
  padding: 0 10px 8px 10px;
}

.dashboard_messagePost_icon {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  font-weight: 600;
  padding: 7px;
  color: var(--color-secondary);
  border-radius: 10px;
  font-size: 14px;
}

.dashboard_message_product_actions {
  width: 100%;
  height: 80px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.dashboard_message_product_input_wrap {
  display: flex;
  flex-direction: column;
}

.dashboard_message_product_input {
  margin-top: 8px;
  appearance: none;
  height: 35px;
  border: 1px solid var(--border-color);
  border-radius: 5px;
  padding: 0 12px;
}

.dashboard_message_product_input:focus {
  display: block;
  border: 1px solid var(--pri-blue);
}

.dashboard_message_product_add_image {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.dashboard_message_product_image {
  height: 120px;
  width: 120px;
  object-fit: cover;
  margin: 8px;
}

/* ----------------------- Create Product -------------------------------- */

/* ------------------- Messages ------------------- */
.messages {
  padding: 10px;
  height: 65vh;
  scroll-behavior: smooth;
  overflow-y: scroll;
}

.messages_container {
  width: 80%;
  display: flex;
  padding: 10px;
  cursor: pointer;
  align-items: center;
  margin: 0 auto;
  background-color: #fff;
}

.messages_active_user {
  width: 12px;
  height: 12px;
  background-color: green;
  border-radius: 50%;
  position: absolute;
  top: 2px;
  right: 2px;
}
.messages_unActive_user {
  width: 12px;
  height: 12px;
  background-color: rgb(208, 207, 207);
  border-radius: 50%;
  position: absolute;
  top: 2px;
  right: 2px;
}

.seller_message_wrap {
  display: flex;
  width: 100%;
  margin: 10px 0px;
  background-color: var(--pri-blue);
  color: #fff;
  padding: 3px 10px;
  width: max-content;
  border-radius: 5px;
}

.seller_message_image {
  border-radius: 50%;
  height: 40px;
  width: 40px;
  margin-right: 10px;
}

.sender_message_wrap {
  display: flex;
  width: 100%;
  margin: 10px 0px;
  background-color: #fff;
  border: 1px solid var(--pri-blue);
  color: var(--pri-blue);
  padding: 3px 10px;
  width: max-content;
  border-radius: 5px;
}

.message_image {
  border-radius: 50%;
  height: 40px;
  width: 40px;
  margin-left: 10px;
}

.sender_message_image {
  border-radius: 50%;
  height: 40px;
  width: 40px;
}

.sender_message_name {
  font-size: 18px;
  font-weight: 600;
}

.sender_message {
  margin-left: 10px;
  padding-bottom: 10px;
}

.active_message {
  cursor: pointer;
}

.unActive_message {
  background: transparent;
  cursor: pointer;
}

.message_timeago {
  color: #00000090;
  font-size: 12px;
  margin-top: -5px;
}

/* ------------- Seller Inbox ----------- */
.seller_inbox {
  width: 100%;
  height: min-content;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.seller_inbox_header {
  width: 100%;
  display: flex;
  padding: 10px;
  align-items: center;
  justify-content: space-between;
}

.seller_inbox_image {
  border-radius: 50%;
  height: 60px;
  width: 60px;
}

.seller_inbox_name {
  font-size: 18px;
  font-weight: 600;
}

/* ------------- Seller Inbox ----------- */

/* send message input */
.message_input {
  padding: 10px;
  position: relative;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.message_send_icon {
  position: absolute;
  right: 15px;
  top: 13px;
  cursor: pointer;
}
/* send message input */
/* ------------------- Messages ------------------- */

/* ------------------- All Orders ------------------- */

.all_orders_container {
  position: absolute;
  left: 30%;
  top: 70px;
  width: 60%;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 1px 2px var(--shadow-1);
  margin: 1.1rem auto;
}

/* ------------------- All Orders ------------------- */

/* ------------------- Shop Dashboard Hero ------------------- */

.shop_dashboard_hero {
  position: absolute;
  left: 35%;
  top: 70px;
  width: 60%;
  margin: 1.1rem auto;
  padding: 20px;
}

.sd_hero_title {
  font-size: 22px;
  padding-bottom: 10px;
}

.sd_hero_container {
  display: flex;
  width: 100%;
  max-width: 800px;
  align-items: center;
  justify-content: space-between;
}

.sd_hero_item {
  width: 30%;
  margin-bottom: 10px;
  min-height: 20vh;
  background-color: #fff;
  box-shadow: 0 1px 2px var(--shadow-1);
  border-radius: 10px;
  padding: 10px 10px;
}

.sd_hero_wrap {
  display: flex;
  align-items: center;
}

.sd_hero_wrap span {
  font-size: 12px;
}

.sd_hero_subtitle {
  margin: 10px 0px 10px 0px;
  font-size: 18px;
  color: #00000085;
}

.sd_hero_charge {
  font-size: 16px;
}

.sd_hero_withdraw_button {
  display: flex;
  background-color: var(--green-color);
  color: #fff;
}

/* ------------------- Shop Dashboard Hero ------------------- */

/* ------------------- Shop Order Details ------------------- */
.shop_order {
  margin: 100px auto;
  display: flex;
  justify-content: center;
}

.shop_orders_container {
  width: 60%;
  background-color: #fff;
  padding: 20px;
  border-radius: 20px;
}

.shop_order_details {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.shop_details_info {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  font-size: 24px;
}

.shop_details_info span {
  position: absolute;
  right: 0px;
  top: 0px;
}

.shop_order_item_image {
  height: 20%;
  width: 20%;
}

/* ------------------- Shop Order Details ------------------- */

/* ------------------- Media Queries ------------------- */

@media (max-width: 1402px) {
  .shop_profile_data {
    left: 18%;
    width: 84%;
  }
}

@media (max-width: 1320px) {
  .all_orders_container {
    left: 35%;
  }
  .all_products_container {
    left: 35%;
  }
  .createProduct {
    left: 35%;
  }
}

@media (max-width: 1160px) {
  .sd_hero_item {
    width: 32%;
  }
  .spd_bottom_container {
    display: flex;
    flex-wrap: wrap;
    max-width: 1200px;
    justify-content: center;
  }
}

@media (max-width: 1060px) {
  .spd_text {
    font-size: 18px;
    padding: 0;
  }
}

@media (max-width: 990px) {
  .shop_profile_data {
    left: 18%;
    width: 80%;
  }
}

@media (max-width: 942px) {
  .shop_dashboard_hero {
    left: 37%;
    width: 50%;
    margin: 1.1rem auto;
    padding: 20px;
  }

  .sd_hero_container {
    flex-direction: column;
  }
  .sd_hero_item {
    width: 100%;
    min-width: 200px;
  }
}

@media (max-width: 890px) {
  .spd_text {
    font-size: 16px;
  }
}

@media (max-width: 860px) {
  .shop_info_image {
    width: 120px;
    height: 120px;
  }
}

@media (max-width: 760px) {
  .shop_info_left {
    width: 20%;
    padding: 60px 10px;
  }
  .shop_profile_data {
    left: 20%;
  }
}

@media (max-width: 700px) {
  .shop_info_image {
    width: 80px;
    height: 80px;
  }
}

@media (max-width: 660px) {
  .all_orders_container {
    left: 30%;
  }
  .all_products_container {
    left: 30%;
  }
  .createProduct {
    left: 30%;
  }
  .shop_info_image {
    width: 80px;
    height: 80px;
  }

  .shop_info_left {
    width: 22%;
    padding: 60px 0px;
  }

  .shop_profile_data {
    left: 22%;
  }
}

@media (max-width: 610px) {
  .spd_text {
    font-size: 16px;
  }
  .spd_tab {
    margin: 0 25px;
  }

  .shop_info_left {
    width: 100%;
    height: 440px;
    background-color: #fff;
    padding: 20px 20px;
    margin-top: 100px;
  }

  .shop_info_left_data {
    margin-top: 0px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  .shop_info_image {
    width: 120px;
    height: 120px;
  }

  .shop_info_description {
    display: flex;
    text-align: center;
    justify-content: center;
  }

  .shop_profile_data {
    left: 0%;
    width: 100%;
    margin-top: 580px;
  }
}

@media (max-width: 560px) {
  .spd_text {
    font-size: 16px;
  }
  .spd_tab {
    margin: 0 20px;
  }
}

@media (max-width: 542px) {
  .spd_tab {
    margin: 0 15px;
  }
}

@media (max-width: 500px) {
  .spd_text {
    font-size: 14px;
  }
}
