.products {
  position: relative;
  display: flex;
  width: 100%;
  height: fit-content;
  /* flex-direction: column; */
  /* max-width: 230px; */
  height: 320px;
  /* background: #fff; */
  /* border-radius: 10px; */
  /* box-shadow: 0 1px 2px var(--shadow-1); */
  user-select: none;
  /* color: var(--color-primary); */
  margin: 10px 10px 10px 10px;
  background-color: red;
}

.product_image {
  width: 100%;
  height: 180px;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
}

.product_container {
  display: flex;
  position: relative;
  /* width: 100%; */
}

.product_name {
  display: flex;
  align-items: center;
  gap: 5px;
  font-size: 3rem;
  font-weight: 600;
  line-height: 19px;
  color: var(--color-primary);
  text-overflow: clip;
  background-color: red !important;
}

.product_price {
  color: var(--pri-blue);
}

.product_bottom {
  padding: 5px 10px 10px 10px;
  position: relative;
  background-color: blue;
}

/* -------------------product details ----------------------- */
.product_details_container {
  margin-top: 50px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.pd_wrap {
  position: relative;
  width: 100%;
  max-width: 1200px;
  height: 600px;
  display: flex;
  margin: 0 auto 0 auto;
  justify-content: space-between;
  padding: 0px 20px;
}

.pd_wrap_left {
  position: relative;
  width: 45%;
  height: 600px;
  padding: 20px 0px;
}

.pd_wrap_right {
  position: relative;
  width: 45%;
}

.pd_images_container {
  position: relative;
  width: 20%;
  display: flex;
  flex-direction: column;
  background-color: blue;
}

.pd_images_wrap {
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin: 30px 10px 0px 0px;
  left: 0;
  width: 100%;
  max-width: 100px;
  /* background-color: red; */
}

.pd_images {
  background-color: #fff;
  width: 100%;
  height: 100px;
  margin: 10px 10px 10px 0px;
}

.pd_image_select {
  position: absolute;
  right: 0;
  width: 80%;
  height: 560px;
  margin-top: 40px;
  border: 2px solid #fff;
}

.pd_info {
  width: 100%;
  margin-top: 50px;
  /* position: absolute;
  right: 0;
  top: 0; */
}
.pd_name {
  font-size: 2rem;
  text-transform: capitalize;
}

.pd_description {
  margin-top: 10px;
  font-size: 1rem;
  text-transform: capitalize;
}

.pd_description_short {
  display: none;
}

.pd_sizes {
  margin-top: 20px;
}

.pd_price_wrap {
  display: flex;
  margin: 20px 0;
}

.pd_delivery_date {
  color: gray;
  font-size: 14px;
}

.pd_actions {
  display: flex;
  position: relative;
}

.pd_actions svg {
  margin-top: 5px;
  margin-left: 15px;
}

.pd_actions_buttons {
  display: flex;
  justify-content: space-between;
  width: 320px;
}

.pd_count {
  color: gray;
  font-weight: 400;
  padding: 10px 20px;
}

.pd_decremement_button {
  color: #fff;
  font-weight: 600;
  font-size: 18px;

  padding: 10px 16px;
  border-radius: 5px;
  box-shadow: 0px 5px 15px lightblue;
  transition: cubic-bezier(0.075, 0.82, 0.165, 1);
  border: none;
  cursor: pointer;
  background: rgb(94, 151, 183);
  background: linear-gradient(
    95deg,
    rgba(94, 151, 183, 1) 31%,
    rgba(94, 151, 183, 0.6895892145920868) 74%
  );
}

.pd_increment_button {
  font-size: 18px;
  color: #fff;
  font-weight: 600;
  padding: 10px 16px;
  border-radius: 5px;
  box-shadow: 0px 5px 15px lightblue;
  transition: cubic-bezier(0.075, 0.82, 0.165, 1);
  border: none;
  cursor: pointer;
  background: rgb(94, 151, 183);
  background: linear-gradient(
    263deg,
    rgba(94, 151, 183, 1) 31%,
    rgba(94, 151, 183, 0.6895892145920868) 74%
  );
}

.pd_cart_button {
  margin-top: 20px;
  border-radius: 5px;
  width: 140px;

  height: 40px;
  background-color: red;
  font-size: 20px;
  font-weight: 400;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.pd_seller_info_wrap {
  margin-top: 20px;
  display: flex;
}

.pd_seller_name {
  color: blue;
  font-size: 16px;
}

.shop_avatar {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-right: 10px;
}

.pdi {
  background-color: #f5f6f6;
  position: relative;
  margin-top: 180px;
}

.pdi_container {
  width: 100%;
  max-width: 1400px;
  padding-top: 40px;
  padding-bottom: 10px;
  justify-content: space-around;
  display: flex;
  margin: 0 auto;
}

.pdi_wrap {
  position: relative;
}

.pdi_title {
  font-size: 18px;
  padding: 0 5px;
  font-weight: 600;
  cursor: pointer;
}

.pdi_info {
  padding: 0 20px 0px 20px;
  line-height: 32px;
  width: 100%;
  max-width: 1400px;
  margin: 0 auto 40px auto;
  /* white-space: pre; */
}

.pdi_review {
  width: 100%;
  justify-content: center;
  min-height: 50vh;
  display: flex;
  align-items: center;
}

.pdi_rating {
  padding-bottom: 10px;
  font-size: 15px;
}

.pdi_seller_info {
  width: 100%;
  padding: 20px;
  min-height: 50vh;
}
/* -------------------product details ----------------------- */

/* -------------------product hero ----------------------- */
.product_hero {
  position: relative;
  display: flex;
  width: 100%;
  max-width: 1400px;
  height: 500px;
  justify-content: center;
  margin: 50px auto 70px auto;
  flex-direction: column;
  top: 64px;
}

.product_hero_image {
  width: 100%;
  height: 500px;
  object-fit: cover;
  background-position: center;
  border-radius: 20px;
}

/* -------------------product hero ----------------------- */

/* similar home categories */
/* -------------------product hero card ----------------------- */

.phc_container {
  position: absolute;
  width: 94%;
  display: flex;
  justify-content: space-between;
  z-index: 60;
  top: 30px;
  left: 40px;
}

.phc_card {
  position: relative;
  background-color: #fff;
  height: 360px;
  width: 25%;
  padding: 10px;
  border: 1px solid var(--pri-ylw);
}

.phc_title {
  position: absolute;
  font-size: 28px;
  /* font-weight: 500; */
  width: 100%;
  height: 40px;
}

.phc_subTitle {
  position: absolute;
  bottom: 10px;
  left: 20px;
  color: var(--pri-blue);
  font-size: 18px;
}

.phc_images {
  position: absolute;
  top: 60px;
  left: 8px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  height: 260px;
  width: 94%;
}

.phc_wrap_0 {
  height: 110px;
  width: 40%;
}

.phc_image {
  width: 100%;
  height: 80%;
}

/* -------------------product hero card ----------------------- */

/* ------------------- Media Queries ----------------------- */
@media (max-width: 1400px) {
  .product_hero_image {
    width: 100%;
  }
  .pd_wrap_left {
    width: 50%;
  }
}

@media screen and (max-width: 1152px) {
  .pd_images {
    height: 90px;
  }

  .pd_image_select {
    height: 440px;
  }
}

@media screen and (max-width: 1100px) {
  .phc_container {
    left: 35px;
  }
  .phc_card {
    width: 30%;
  }
}

@media screen and (max-width: 1086px) {
  .phc_container {
    left: 30px;
  }
}

@media screen and (max-width: 1048px) {
  .pd_images {
    width: 90%;
    height: 80px;
  }

  .pd_image_select {
    height: 420px;
  }
}

@media screen and (max-width: 998px) {
  .pd_images {
    width: 90%;
    height: 70px;
  }

  .pd_image_select {
    height: 400px;
  }

  .pd_name {
    font-size: 1.5rem;
  }

  .pdi {
    margin-top: 60px;
  }
}

@media screen and (max-width: 968px) {
  .phc_card {
    width: 36%;
  }
}

@media screen and (max-width: 940px) {
  .shop_avatar {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    margin-right: 10px;
  }
}

@media screen and (max-width: 904px) {
  .phc_container {
    left: 25px;
  }
}

@media screen and (max-width: 888px) {
  .pd_images {
    height: 60px;
  }

  .pd_image_select {
    height: 400px;
  }
}

@media screen and (max-width: 862px) {
  .pd_images_container {
    width: 300px;
  }
  .pd_images_wrap {
    top: 400px;
    flex-direction: row;
  }
  .pd_images {
    width: 160px;
    height: 80px;
  }
  .pd_image_select {
    height: 400px;
    width: 100%;
  }

  .pdi {
    margin-top: 80px;
  }
}

@media screen and (max-width: 860px) {
  .pd_image_select {
    height: 380px;
  }
}

@media screen and (max-width: 840px) {
  .phc_card {
    width: 40%;
  }
}

@media screen and (max-width: 768px) {
  .pd_description {
    font-size: 0.9rem;
  }
  .phc_title {
    font-size: 24px;
  }
}

@media screen and (max-width: 756px) {
  .phc_container {
    left: 20px;
  }
}

@media screen and (max-width: 728px) {
  .phc_container {
    left: 20px;
  }
  .phc_card {
    width: 42%;
  }

  .pd_actions_buttons {
    flex-direction: column;
    width: 200px;
  }

  .pdi {
    margin-top: 120px;
  }
}

@media (max-width: 692px) {
  .phc_card {
    width: 44%;
  }
}

@media (max-width: 660px) {
  .phc_card {
    width: 46%;
  }
}

@media (max-width: 640px) {
  .pdi {
    margin-top: 140px;
  }
}

@media (max-width: 614px) {
  .pd_image_select {
    height: 340px;
  }
  .pd_images_wrap {
    top: 360px;
    flex-direction: row;
  }

  .pd_name {
    font-size: 1.3rem;
  }

  .pd_description {
    font-size: 0.8rem;
  }
}

@media (max-width: 590px) {
  .phc_container .phc_card:nth-child(2) {
    display: none;
  }

  .phc_card {
    left: 20%;
    width: 60%;
  }
}

@media (max-width: 572px) {
  .pd_decremement_button {
    padding: 8px 14px;
  }
  .pd_increment_button {
    padding: 8px 12px;
  }
}

@media (max-width: 554px) {
  .pd_images_wrap {
    top: 320px;
    flex-direction: row;
  }
  .pd_image_select {
    width: 100%;
    height: 300px;
  }
}

@media (max-width: 514px) {
  .pd_shipping_wrap {
    margin-top: 20px;
    border: 1px solid lightgray;
    border-radius: 5px;
    padding: 5px 0px 5px 5px;
  }
  .pdi {
    margin-top: 200px;
  }
}

@media (max-width: 494px) {
  .pd_name {
    font-size: 1.1rem;
  }

  .pd_images {
    height: 70px;
  }

  .phc_card {
    left: 15%;
    width: 70%;
  }
}

@media (max-width: 460px) {
  .phc_card {
    left: 13%;
    width: 70%;
  }
  .pd_image_select {
    height: 220px;
  }
  .pd_images_wrap {
    top: 230px;
  }

  .pdi_title {
    font-size: 16px;
  }
  .pdi_info {
    font-size: 14px;
  }
}

@media (max-width: 436px) {
  .pd_images {
    height: 60px;
  }
  .phc_card {
    left: 12%;
    width: 75%;
  }
  .pdi {
    margin-top: 230px;
  }
}

@media (max-width: 420px) {
  .pd_decremement_button {
    padding: 6px 10px;
  }
  .pd_increment_button {
    padding: 6px 8px;
  }
  .pd_count {
    padding: 10px 14px;
  }
}

@media (max-width: 406px) {
  .pd_image_select {
    height: 200px;
  }
  .pd_images_wrap {
    top: 210px;
    width: 20%;
  }
  .pdi {
    margin-top: 240px;
  }
  .pdi_title {
    font-size: 14px;
  }
  .pdi_info {
    font-size: 14px;
  }
}

@media (max-width: 398px) {
  .pd_images {
    height: 50px;
  }
  .pdi {
    margin-top: 280px;
  }
  .phc_card {
    left: 8%;
    width: 80%;
  }
}

@media (max-width: 384px) {
  .pd_image_select {
    height: 180px;
  }
  .pd_images_wrap {
    top: 190px;
  }
  .pd_decremement_button {
    padding: 6px 10px;
  }
  .pd_increment_button {
    padding: 6px 8px;
  }
  .pd_count {
    padding: 10px 14px;
  }
  .pdi {
    margin-top: 300px;
  }
  .black_button {
    width: 140px !important;
    font-size: 0.9rem !important;
  }
}

@media (max-width: 362px) {
  .pdi {
    margin-top: 320px;
  }
}
