.wishlist_container {
  position: fixed;
  top: 50px;
  right: 0px;
  min-height: 90vh;
  padding: 10px;
  width: 24%;
  background: #fff;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  box-shadow: 0px 5px 15px gray;
  border-radius: 10px;
  z-index: 999999;
}

.wishlist_header {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: flex-end;
  padding-top: 5px;
  padding-right: 5px;
}

.wishlist_close_icon {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 10px;
}

.wishlist_single_container {
  position: absolute;
  top: 100px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 90%;
  margin-left: 3%;
}

.wishlist_single {
  position: relative;
  border: 1px solid lightgray;
  margin: 20px 10px;
  padding: 10px;
  border-radius: 10px;
  height: 140px;
  height: 160px;
}

.wishlist_single_wrap {
  position: relative;
  display: flex;
  width: 100%;
  align-items: center;
  border: lightgray;
  align-items: center;
  height: 100px;
}

.wishlist_single_left {
  position: absolute;
  top: 22px;
  display: flex;
  width: 40%;
  align-items: center;
}

.wishlist_single_right {
  position: absolute;
  display: flex;
  width: 30%;
  left: 45%;
  top: 20px;
  margin-left: 10px;
}

.wishlist_text {
  position: relative;
  width: 100%;
}

.wishlist_image {
  height: 90px;
  width: 65%;
  margin-left: 8px;
  margin-right: 8px;
  border-radius: 5px;
}

/* ---------------- Media Queries ------------------ */
@media (max-width: 1520px) {
  .wishlist_container {
    width: 25%;
  }
  .wishlist_single {
    height: 140px;
  }
  .wishlist_single_left {
    width: 55%;
    top: 20px;
  }

  .wishlist_single_right {
    width: 40%;
    left: 45%;
    margin-left: 5px;
  }

  .wishlist_image {
    height: 70px;
    width: 55%;
  }

  .wishlist_name {
    margin-bottom: 5px;
  }

  .wishlist_price {
    margin-bottom: 5px;
  }
}

@media (max-width: 1274px) {
  .wishlist_single_container {
    margin-left: 0%;
  }
  .wishlist_single {
    width: 98%;
  }
  .wishlist_single_left {
    width: 45%;
    top: 20px;
  }
  .wishlist_single_right {
    width: 40%;
    left: 45%;
    margin-left: -5px;
  }
}
@media (max-width: 1120px) {
  .wishlist_container {
    width: 30%;
  }
}

@media (max-width: 880px) {
  .wishlist_container {
    width: 35%;
  }
}

@media (max-width: 760px) {
  .wishlist_container {
    width: 40%;
  }
}

@media (max-width: 660px) {
  .wishlist_container {
    width: 45%;
  }
}

@media (max-width: 600px) {
  .wishlist_header {
    top: 45px;
  }
  .wishlist_close_icon {
    position: absolute;
    top: 5px;
    right: 10px;
  }
}

@media (max-width: 560px) {
  .wishlist_container {
    width: 50%;
  }
}

@media (max-width: 490px) {
  .wishlist_container {
    width: 60%;
  }
}

@media (max-width: 420px) {
  .wishlist_container {
    width: 70%;
  }
}

@media (max-width: 380px) {
  .wishlist_container {
    width: 80%;
  }
}
