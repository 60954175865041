/* hpb = home_page_banner */
/* ppb = product_page_banner */

/* ----------------------home Banner ---------------------- */
.home_page_banner {
  position: relative;
  background-color: #fff;
  border: 1px solid var(--pri-ylw);
  box-shadow: 5px 10px 15px var(--shadow-2);
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  width: 98%;
  height: 140px;
  max-width: 1400px;
  margin: 140px auto 0 auto;
  padding: 10px 20px;
  border-radius: 10px;
}

.hpb_info {
  display: flex;
  flex-direction: column;
  position: absolute;
  height: 200px;
  width: 100%;
  top: 10px;
}

.hpb_title {
  width: 100%;
  font-size: 24px;
  font-weight: 700;
}

.hpb_text {
  width: 100%;
  font-size: 1.2rem;
  font-weight: 500;
}

.hpb_button_wrap {
  position: absolute;
  top: 90px;
}

.hpb_image_wrap {
  position: absolute;
  top: 10px;
  right: 0px;
  width: 45%;
  height: 100px;
}

.hpb_small_image_container {
  position: absolute;
  top: -20px;
  right: 320px;
  width: 60%;
  display: flex;
  align-items: center;
}

.hpb_image_1 {
  width: 70%;
  height: 120px;
}
.hpb_image_2 {
  width: 120px;
  height: 120px;
  border-radius: 5px;
}

.hpb_image_plus {
  font-size: 34px;
  color: gray;
}

.hpb_main_image {
  position: absolute;
  top: -130px;
  right: -10px;
  height: 280px;
  width: 50%;
}

/* ----------------------home Banner ---------------------- */

/* ---------------------- Home Page Service Banner ---------------------- */
.home_page_service_banner {
  position: relative;
  background-color: #fff;
  border: 1px solid var(--pri-ylw);
  box-shadow: 5px 10px 15px var(--shadow-2);
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  width: 98%;
  height: 140px;
  max-width: 1400px;
  margin: 280px auto 0px auto;
  padding: 10px 20px;
  border-radius: 10px;
}

.service_banner {
  position: relative;
  background-color: #fff;
  border: 1px solid var(--pri-ylw);
  box-shadow: 5px 10px 15px var(--shadow-2);
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  width: 98%;
  height: 140px;
  max-width: 1400px;
  margin: 100px auto 0px auto;
  padding: 10px 20px;
  border-radius: 10px;
}

.hpsb_info {
  display: flex;
  flex-direction: column;
  position: absolute;
  height: 200px;
  width: 52%;
  top: 10px;
}

.hpsb_title {
  width: 100%;
  font-size: 24px;
  font-weight: 700;
}

.hpsb_text {
  width: 100%;
  font-size: 1.2rem;
  font-weight: 500;
}

.hpsb_button_wrap {
  position: absolute;
  top: 90px;
}

.hpsb_small_image_container {
  position: absolute;
  top: -20px;
  right: 360px;
  width: 400px;
  display: flex;
  align-items: center;
}

.hpsb_image_1 {
  width: 120px;
  height: 120px;
  border-radius: 5px;
}
.hpsb_image_2 {
  width: 120px;
  height: 120px;
  margin-left: 40px;
  border-radius: 5px;
}

.hpsb_image_plus {
  font-size: 34px;
  margin-left: 30px;
  color: gray;
}

.hpsb_main_image_wrap {
  display: flex;
  width: 48%;
  flex-direction: column;
  justify-content: center;
  position: absolute;
  top: -60px;
  right: 10px;
  text-align: center;
}

.hpsb_main_image {
  height: 170px;
  width: 100%;
}

.hpsb_creator_text {
  font-size: 0.8rem;
  margin-left: -10px;
}

/* ----------------------Home Page Service Banner ---------------------- */

/* ---------------------- Home Page Product Banner ---------------------- */
.home_page_product_banner {
  position: relative;
  background-color: #fff;
  border: 1px solid var(--pri-ylw);
  box-shadow: 5px 10px 15px var(--shadow-2);
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  width: 98%;
  height: 140px;
  max-width: 1400px;
  margin: 120px auto 0px auto !important;
  padding: 10px 20px;
  border-radius: 10px;
}

.hppb_info {
  display: flex;
  flex-direction: column;
  position: absolute;
  height: 200px;
  width: 52%;
  top: 10px;
}

.hppb_title {
  width: 100%;
  font-size: 24px;
  font-weight: 700;
}

.hppb_text {
  width: 100%;
  font-size: 1.2rem;
  font-weight: 500;
}

.hppb_button_wrap {
  position: absolute;
  top: 90px;
}

.hppb_small_image_container {
  position: absolute;
  top: -20px;
  right: 360px;
  width: 400px;
  display: flex;
  align-items: center;
}

.hppb_image_1 {
  width: 120px;
  height: 120px;
  border-radius: 5px;
}
.hppb_image_2 {
  width: 120px;
  height: 120px;
  margin-left: 40px;
  border-radius: 5px;
}

.hppb_image_plus {
  font-size: 34px;
  margin-left: 30px;
  color: gray;
}

.hppb_main_image_wrap {
  display: flex;
  width: 48%;
  flex-direction: column;
  justify-content: center;
  position: absolute;
  top: -60px;
  right: 10px;
  text-align: center;
}

.hppb_main_image {
  height: 170px;
  width: 100%;
}

.hppb_creator_text {
  font-size: 0.8rem;
  margin-left: -10px;
}

/* ----------------------Home Page Product Banner ---------------------- */

/* ----------------------Product Banner ---------------------- */
.product_page_banner_container {
  width: 100%;
  margin-top: 60px;
}

.product_page_banner {
  background-color: #fff;
  border: 1px solid var(--pri-ylw);
  box-shadow: 5px 10px 15px var(--shadow-2);
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 460px;
  max-width: 1400px;
  margin: 60px auto;
  padding: 10px 20px;
  border-radius: 10px;
}

.ppb_col_1 {
  position: relative;
  width: 420px;
}

.ppb_wrap {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
  height: 420px;
}

.ppb_info {
  display: flex;
  flex-direction: column;
  position: absolute;
  height: 200px;
  width: 100%;
  /* top: 20px; */
  justify-content: center;
}

.ppb_title {
  font-size: 28px;
  font-weight: 700;
}

.ppb_text {
  font-size: 18px;
  font-weight: 500;
  /* color: #fff; */
  /* color: var(--pri-blue); */
}

.ppb_images_col_1 {
  position: absolute;
  height: 180px;
  width: 100%;
  bottom: 30px;
  display: flex;
  justify-content: space-between;
}

.ppb_card {
  position: relative;
}

.ppb_image {
  width: 180px;
  height: 180px;
  border-radius: 10px;
}

.ppb_card_price {
  position: absolute;
  bottom: 10px;
  left: 10px;
  background-color: #fff;
  padding: 5px 10px;
  border-radius: 10px;
  width: 80px;
  font-size: 14px;
}

.ppb_col_2 {
  position: relative;
  width: 430px;
}

.ppb_images_col_2 {
  height: 200px;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.ppb_images_col_2_top {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 40px;
  margin-top: 10px;
}

.ppb_images_col_2_bottom {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.ppb_col_3 {
  position: relative;
  width: 400px;
  height: 458px;
  margin-top: -19px;
  margin-right: -20px;
}

.ppb_main_image {
  width: 100%;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  height: 458px;
}

/* ----------------------Product Banner ---------------------- */

/* ----------------------Service Page Banner ---------------------- */

.spb_main_image_wrap {
  display: flex;
  /* width: 580px; */
  width: 30%;
  flex-direction: column;
  justify-content: center;
  position: absolute;
  top: -60px;
  right: 10px;
  text-align: center;
}

.spb_main_image {
  height: 190px;
  width: 100%;
}

/* ----------------------Service Page Banner ---------------------- */

/* ----------------------media quires ---------------------- */

@media (max-width: 1386px) {
  .hpb_small_image_container {
    right: 280px;
  }
}

@media (max-width: 1268px) {
  .hpb_small_image_container {
    right: 270px;
  }
}

@media (max-width: 1200px) {
  .hpb_small_image_container {
    right: 240px;
  }
}
@media (max-width: 1090px) {
  .hpsb_main_image_wrap {
    display: flex;
    width: 47%;
    flex-direction: column;
    justify-content: center;
    position: absolute;
    right: 10px;
    text-align: center;
  }

  .home_page_product_banner {
    margin: 100px auto 0px auto !important;
  }

  .hppb_main_image_wrap {
    display: flex;
    width: 47%;
    flex-direction: column;
    justify-content: center;
    position: absolute;
    right: 10px;
    text-align: center;
  }
  .hpb_small_image_container {
    right: 220px;
  }
}

@media (max-width: 1030px) {
  .hpb_small_image_container {
    right: 180px;
  }
  .hpb_main_image {
    top: -120px;
    right: -10px;
    height: 260px;
    width: 45%;
  }
}

@media (max-width: 990px) {
  .hpsb_text {
    font-size: 1.1rem;
  }

  .hppb_text {
    font-size: 1.1rem;
  }
  .hpb_text {
    font-size: 1.1rem;
  }
  /* .hpb_image_1 {
    width: 110px;
    height: 110px;
  } */
}

@media (max-width: 920px) {
  .hpb_small_image_container {
    top: 0px;
    right: 160px;
  }
  .hpb_image_1 {
    display: none;
  }
  .hpb_image_2 {
    display: none;
  }
  .hpb_info {
    width: 50%;
  }
  .hpb_image_plus {
    display: none;
  }
  .hpb_main_image {
    top: -110px;
    height: 240px;
    width: 380px;
  }
}

@media (max-width: 808px) {
  .home_page_service_banner {
    padding: 10px;
    /* margin-top: 60px; */
  }
  .home_page_product_banner {
    padding: 10px;
    /* margin-top: 60px; */
  }
  .hpsb_text {
    font-size: 1rem;
  }

  .hpsb_main_image_wrap {
    right: 0px;
  }

  .hpsb_main_image {
    height: 160px;
    width: 98%;
  }
  .hppb_text {
    font-size: 1rem;
  }

  .hppb_main_image_wrap {
    right: 0px;
  }

  .hppb_main_image {
    height: 160px;
    width: 98%;
  }
  .spb_main_image_wrap {
    width: 40%;
  }
}

@media (max-width: 746px) {
  .home_page_product_banner {
    margin: 70px auto 0px auto !important;
  }
}

@media (max-width: 662px) {
  .hpb_main_image {
    top: -90px;
    right: -10px;
    height: 220px;
    width: 340px;
  }
}

@media (max-width: 626px) {
  .hpb_main_image {
    top: -80px;
    height: 210px;
    width: 340px;
  }
}

@media (max-width: 608px) {
  .home_page_banner {
    margin-top: 340px;
  }

  .hpsb_main_image_wrap {
    right: 0px;
    top: -30px;
  }

  .hpsb_main_image {
    height: 140px;
    width: 98%;
  }
  .hppb_main_image_wrap {
    right: 0px;
    top: -30px;
  }

  .hppb_main_image {
    height: 140px;
    width: 98%;
  }
  .hpb_text {
    font-size: 1rem;
  }
  .hpb_main_image {
    top: -60px;
    height: 190px;
    width: 320px;
  }
  .home_page_service_banner {
    width: 98%;
    margin-top: 500px;
  }
  .home_page_product_banner {
    width: 98%;
    margin: 360px auto 0px auto !important;
  }
}

@media (max-width: 546px) {
  .hpb_main_image {
    top: -40px;
    height: 170px;
    width: 280px;
  }
}

@media (max-width: 518px) {
  .hpb_info {
    width: 55%;
  }
  .hpb_title {
    font-size: 22px;
  }

  .hpb_text {
    font-size: 15px;
  }

  .hpb_main_image {
    top: -40px;
    right: -10px;
    height: 170px;
    width: 98%;
  }
}

@media (max-width: 468px) {
  .hpb_title {
    font-size: 22px;
  }

  .hpb_text {
    font-size: 13px;
  }
}
@media (max-width: 440px) {
  .hpsb_text {
    font-size: 13px;
  }
  .hpsb_main_image {
    height: 130px;
    width: 96%;
  }
  .hpb_title {
    font-size: 21px;
  }
  .hppb_text {
    font-size: 13px;
  }
  .hppb_main_image {
    height: 130px;
    width: 96%;
  }
}

@media (max-width: 430px) {
  .spb_main_image_wrap {
    width: 45%;
  }
}

@media (max-width: 426px) {
  .home_page_product_banner {
    width: 98%;
    margin: 320px auto 0px auto !important;
  }
  .home_page_service_banner {
    margin-top: 540px;
  }
}
/* ----------------------media quires ---------------------- */
