.orders {
  margin: 100px auto;
  display: flex;
  justify-content: center;
}

.orders_container {
  width: 60%;
  background-color: #fff;
  padding: 20px;
  border-radius: 20px;
}

.order_details {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.order_details_info {
  display: flex;
  flex-direction: column;
  font-size: 24px;
}

.order_item_container {
  width: 100%;
  display: flex;
  align-items: start;
  margin-bottom: 20px;
}

.order_item_image {
  height: 20%;
  width: 20%;
}

.order_item_wrap {
  width: 100%;
  padding-left: 10px;
}

.order_item_info {
  font-size: 20px;
}

.order_item_description {
  font-size: 14px;
  color: #00000090;
}

.order_item_button {
  position: absolute;
  right: 21%;
  min-width: 120px;
}

.order_details_bottom_container {
  width: 100%;
  text-align: right;
  border-top: 1px solid var(--border-color);
  padding-top: 10px;
}

/* ------------------- Order Modal ------------------ */
.order_details_modal {
  width: 100%;
  position: fixed;
  top: 0px;
  left: 0px;
  height: 100vh;
  background: #00000050;
  z-index: 500;
  display: flex;
  align-items: center;
  justify-content: center;
}

.odm_container {
  /* top: 70px;
  position: absolute; */
  background-color: #fff;
  width: 50%;
  height: content;
  border-radius: 20px;
  padding: 20px;
}

.odm_close_wrap {
  display: flex;
  justify-content: flex-end;
}

.odm_close {
  cursor: pointer;
}

.odm_title {
  display: flex;
  justify-content: center;
  font-size: 30px;
  font-weight: 500;
}

.odm_comment_container {
  width: 100%;
}

.odm_comment {
  font-size: 20px;
  font-weight: 500;
  display: flex;
  flex-direction: column;
}

.odm_comment span {
  margin-left: 6px;
  font-weight: 400;
  font-size: 14px;
  color: #00000080;
}

/*------------------- Order Modal ------------------ */
/*------------------- Media Queries ------------------ */

@media (max-width: 600px) {
  .orders_container {
    width: 80%;
  }
  .odm_container {
    background-color: #fff;
    width: 80%;
    height: content;
    border-radius: 20px;
    padding: 20px;
  }

  .order_item_button {
    right: 13%;
    /* top: 420px; */
  }
}

@media (max-width: 422px) {
  .order_item_button {
    right: 13%;
    top: 420px;
  }
}
/*------------------- Media Queries ------------------ */
