.service {
  width: 100%;
  display: flex;
  flex-direction: column;
  height: 400px;
  margin-top: 60px;
}

.service_container {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  max-width: 1400px;
  margin: 20px auto 0px auto;
  padding: 10px;
  justify-content: space-between;
}

.service_wrap {
  display: flex;
  flex-direction: column;
  width: 30%;
  height: 220px;
  border-radius: 10px;
  box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.1);
  position: relative;
  background: #fff;
  margin-bottom: 40px;
  cursor: pointer;
}

.service_wrap_bottom {
  display: flex;
  flex-direction: column;
  padding: 5px;
}

.service_wrap_name {
  font-weight: 700;
  font-size: 1.1rem;
}

.service_wrap_info {
  color: gray;
  display: flex;
  font-size: 14px;
  margin-top: 5px;
}

.service_wrap_image {
  width: 100%;
  height: 160px;
  min-height: 160px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  background-color: antiquewhite;
}

.service_timings {
  color: gray;
  font-size: 12px;
  margin-top: 10px;
}

.db_header {
  display: flex;
  width: 100%;
  height: 49px;
  background: #fff;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 30;
  align-items: center;
  justify-content: space-between;
  padding: 0 15px;
  box-shadow: 1px 8px 15px -7px var(--shadow-2);
}

/* shop_product_data */
.service_profile_data {
  width: 100%;
}

.shop_profile_profile {
  position: absolute;
  right: -80px;
  display: flex;
  width: 100%;
  min-width: 600px;
  justify-content: center;
}

.spd_tab_profile {
  display: flex;
  justify-content: space-between;
  margin: 0px 20px;
}

.spd_container_profile {
  position: relative;
  width: 80%;
  background-color: blue;
}

.spd_container {
  width: 100%;
  display: flex;
}

.spd_top {
  display: flex;
  width: 100%;
}

/* ----------------------- Create Service -------------------------------- */
.create_service {
  width: 80%;
  position: relative;
}

.createService {
  background: #fff;
  border-radius: 10px;
  box-shadow: 0 1px 2px var(--shadow-1);
  margin: 1.1rem auto;
  height: fit-content;
  margin-bottom: 1.1rem;
  width: 100%;
  max-width: 800px;
  position: absolute;
  left: 500px;
  top: 70px;
}
.createService_header {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  padding: 10px 17px 5px 15px;
  font-size: 20px;
}
.create_splitter {
  height: 1px;
  width: 95%;
  background: var(--bg-third);
  margin: 7px 10px;
}
.createService_body {
  padding: 0 10px 8px 10px;
}

.createPost_icon {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  font-weight: 600;
  padding: 7px;
  color: var(--color-secondary);
  border-radius: 10px;
  font-size: 14px;
}

.create_service_actions {
  width: 100%;
  height: 80px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.create_service_input_wrap {
  display: flex;
  flex-direction: column;
}

.create_service_input {
  margin-top: 8px;
  appearance: none;
  height: 35px;
  border: 1px solid var(--border-color);
  border-radius: 5px;
  padding: 0 12px;
}

.create_service_input:focus {
  display: block;
  border: 1px solid var(--pri-blue);
}

.create_service_add_image {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.create_service_image {
  height: 120px;
  width: 120px;
  object-fit: cover;
  margin: 8px;
}

/* ----------------------- Create Service -------------------------------- */

/* -------------------- All Services -------------------- */
.all_services {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.all_services_container {
  position: absolute;
  left: 500px;
  top: 70px;
  width: 60%;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 1px 2px var(--shadow-1);
  margin: 1.1rem auto;
}

/* .all_products_grid {
  width: 60%;
  background-color: #fff;
  margin: 40px 30px 0px 30px;
  padding: 5px 0px 0px 0px;
} */
/* -------------------- All Services -------------------- */

/* ----------------------- Service Message -------------------------------- */
.dashboard_message {
  width: 80%;
  position: relative;
}

.dashboard_message_container {
  background: #fff;
  border-radius: 10px;
  box-shadow: 0 1px 2px var(--shadow-1);
  margin: 1.1rem auto;
  height: fit-content;
  margin-bottom: 1.1rem;
  width: 100%;
  max-width: 800px;
  position: absolute;
  left: 500px;
  top: 70px;
  overflow-y: scroll;
}
.dashboard_message_header {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  padding: 10px 17px 5px 15px;
  font-size: 20px;
}
.dashboard_message_splitter {
  height: 1px;
  width: 95%;
  background: var(--bg-third);
  margin: 7px 10px;
}
.dashboard_message_body {
  padding: 0 10px 8px 10px;
  /* display: grid;
  grid-template-columns: repeat(3, 1fr); */
}

.dashboard_messagePost_icon {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  font-weight: 600;
  padding: 7px;
  color: var(--color-secondary);
  border-radius: 10px;
  font-size: 14px;
}

.dashboard_message_product_actions {
  width: 100%;
  height: 80px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.dashboard_message_product_input_wrap {
  display: flex;
  flex-direction: column;
}

.dashboard_message_product_input {
  margin-top: 8px;
  appearance: none;
  height: 35px;
  border: 1px solid var(--border-color);
  border-radius: 5px;
  padding: 0 12px;
}

.dashboard_message_product_input:focus {
  display: block;
  border: 1px solid var(--pri-blue);
}

.dashboard_message_product_add_image {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.dashboard_message_product_image {
  height: 120px;
  width: 120px;
  object-fit: cover;
  margin: 8px;
}

/* ----------------------- Service Message -------------------------------- */

/* ------------------- Shop Info ------------------- */
/* .shop_info {
  width: 100px;
  margin-top: 49px;
}

.shop_info_left {
  background-color: #fff;
}

.shop_info_image {
  width: 150px;
  height: 150px;
  border-radius: 50%;
  object-fit: cover;
}

.shop_info_name {
  text-align: center;
  padding: 0px 10px;
  font-size: 20px;
}

.shop_info_description {
  font-size: 16px;
  color: #000000a6;
  padding: 10px;
  display: flex;
  align-items: center;
} */
/* ------------------- Shop Info ------------------- */

/* -------------------service hero ----------------------- */
.service_hero {
  position: relative;
  display: flex;
  width: 98%;
  max-width: 1400px;
  height: 500px;
  justify-content: center;
  margin: 80px auto 70px auto;
  flex-direction: column;
  top: 30px;
  background-image: url("https://res.cloudinary.com/dcinefb4x/image/upload/v1703084369/service_hero_rev3_g3a0d7.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100% 500px;
}

.service_hero_title {
  position: absolute;
  top: -8px;
  font-size: 64px;
  line-height: 68px;
}

.service_hero_button_container {
  position: absolute;
  top: 160px;
  display: flex;
  justify-content: space-between;
  width: 60%;
  margin-left: 8%;
}

.service_hero_button {
  height: 34px;
  width: 100px;
  padding: 5px;
  border-radius: 20px;
  background-color: transparent;
  color: #fff;
  font-size: 1rem;
  border: 1px solid #fff;
  box-shadow: 0 1px 2px var(--shadow-2);
  cursor: pointer;
}

.service_hero_subcategory_card {
  display: flex;
  position: absolute;
  top: 0;
  right: 0;
  height: 280px;
  width: 24%;
  border-top-right-radius: 20px;
  box-shadow: 0 1px 2px var(--shadow-1);
  background-color: #fff;
  border: 1px solid var(--pri-blue);
  padding: 0px 10px;
}

.service_hero_subcategory_title {
  position: absolute;
  font-size: 28px;
  width: 100%;
  height: 40px;
}

.service_hero_subcategory_images {
  position: absolute;
  top: 50px;
  left: 10%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  height: 200px;
  width: 80%;
}

.service_hero_subcategory_wrap {
  height: 80px;
  width: 40%;
}

.service_hero_subcategory_image {
  width: 100%;
  height: 80%;
}

.service_hero_subtitle_container {
  position: absolute;
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 120px;
  top: 0px;
  background-color: var(--bg-blue);
  font-size: 1.5rem;
  font-weight: 700;
  border-bottom: 10px solid var(--bg-blue);
}

.service_hero_subtitle_left {
  width: 50%;
  position: relative;
  border-right: 10px solid var(--bg-blue);
}

.service_hero_subtitle_left_image {
  width: 50%;
  height: 110px;
  border-radius: 5px;
}

.service_hero_subtitle_left_text {
  position: absolute;
  right: 10px;
}

.service_hero_subtitle_right {
  position: relative;
  padding: 10px;
}

/* -------------------service hero ----------------------- */

/* ------------------- Media Queries ------------------- */

@media screen and (max-width: 1174px) {
  .service_wrap_info {
    font-size: 0.8rem;
  }
}

@media screen and (max-width: 1150px) {
  .service_hero_button_container {
    width: 65%;
    margin-left: 5%;
  }
}

@media screen and (max-width: 1004px) {
  .service_hero_title {
    font-size: 60px;
  }

  .service_hero_button_container {
    width: 68%;
    margin-left: 3.5%;
  }
}

@media screen and (max-width: 984px) {
  .service_hero_button_container {
    width: 72%;
    margin-left: 1.2%;
  }
}

@media screen and (max-width: 968px) {
  .service_wrap_info {
    font-size: 0.7rem;
  }
  .service_container {
    margin: 0px auto 0px auto;
  }
}

@media screen and (max-width: 945px) {
  .service_hero_title {
    font-size: 56px;
  }
  .service_hero_subcategory_title {
    font-size: 26px;
  }
}

@media screen and (max-width: 906px) {
  .service_hero_button {
    width: 90px;
    font-size: 14px;
  }
}

@media screen and (max-width: 875px) {
  .service_hero_title {
    font-size: 52px;
  }
  .service_hero_subcategory_title {
    font-size: 24px;
  }
}

@media screen and (max-width: 830px) {
  .service_wrap_info {
    font-size: 0.6rem;
  }
}

@media screen and (max-width: 815px) {
  .service_wrap_info {
    font-size: 0.6rem;
  }

  .service_hero {
    background-image: url("https://res.cloudinary.com/dcinefb4x/image/upload/v1703087973/service_hero_rev3.5_kt5zj5.png");
  }

  .service_hero_button_container {
    margin-left: 3.8%;
    top: 220px;
    width: 50%;
    flex-wrap: wrap;
    justify-content: space-around;
  }

  .service_hero_button {
    width: 110px;
    font-size: 1rem;
    margin-bottom: 15px;
  }

  .service_hero_title {
    top: 20px;
    left: 40px;
    font-size: 45px;
    width: 46%;
    line-height: 50px;
    color: #fff;
  }

  .service_hero_title span span {
    font-size: 30px;
    font-weight: 100;
  }

  .service_hero_subcategory_card {
    top: 40px;
    right: 40px;
    height: 280px;
    width: 34%;
    border-top-right-radius: 0px;
  }
}

@media (max-width: 792px) {
  .service_hero_title {
    font-size: 42px;
  }
}

@media (max-width: 740px) {
  .service_container {
    margin: 0px auto 0px auto;
  }
}

@media (max-width: 710px) {
  .service_hero_button {
    width: 100px;
    font-size: 0.9rem;
  }
}

@media (max-width: 648px) {
  .service_hero_title {
    font-size: 38px;
  }

  .service_hero_title span span {
    font-size: 28px;
  }

  .service_hero_button {
    width: 90px;
    font-size: 0.9rem;
    margin-bottom: 15px;
  }
}

@media (max-width: 608px) {
  .service_wrap {
    width: 48%;
    margin-bottom: 30px;
  }
  .service_wrap_info {
    font-size: 0.7rem;
  }
}

@media (max-width: 588px) {
  .service_hero {
    background-image: url("https://res.cloudinary.com/dcinefb4x/image/upload/v1703091230/service_hero_rev4_hxxxkw.png");
  }

  .service_hero_title {
    top: 10px;
    left: 7%;
    width: 90%;
    line-height: 36px;
    text-align: center;
  }

  .service_hero_subcategory_card {
    top: 160px;
    right: 24%;
    height: 260px;
    width: 50%;
  }

  .service_hero_button_container {
    margin-left: 0.4%;
    top: 95px;
    width: 100%;
    justify-content: center;
  }

  .service_hero_button {
    width: 80px;
    font-size: 0.8rem;
    margin: 5px;
  }
}

@media screen and (max-width: 552px) {
  .service_wrap_info {
    margin-top: 0;
  }

  .service_hero_button_container {
    margin-left: 6%;
    top: 65px;
    width: 90%;
  }

  .service_hero_button {
    width: 100px;
    font-size: 0.8rem;
    margin: 5px 10px;
  }
}

@media screen and (max-width: 522px) {
  .service_wrap_info {
    margin-top: 0;
  }
  .service_hero_subcategory_card {
    right: 22%;
    width: 55%;
  }

  .service_hero_title {
    left: 6%;
  }
}

@media screen and (max-width: 490px) {
  .service_hero_title {
    left: 3%;
    font-size: 36px;
    width: 95%;
  }
}

@media screen and (max-width: 480px) {
  .service_hero_subcategory_card {
    top: 170px;
  }
}

@media screen and (max-width: 432px) {
  .service_hero_title {
    font-size: 32px;
  }

  .service_hero_button {
    width: 90px;
    margin: 5px 5px;
  }

  .service_hero_subcategory_card {
    width: 60%;
    right: 18%;
  }
}

@media screen and (max-width: 390px) {
  .service_hero_title {
    font-size: 30px;
    left: 0%;
    width: 100%;
  }

  .service_hero_subcategory_card {
    width: 70%;
    right: 14%;
  }
}

/* ------------------- Media Queries ------------------- */
