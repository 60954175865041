.canvawww_hero {
  width: 100%;
  height: 100vh;
}

.canvawww_hero_container {
  position: relative;
  width: 100%;
  max-width: 1200px;
  z-index: 999999;
  margin: 0 auto;
}

.canvawww_hero_left {
  position: relative;
  width: 100%;
}

.canvawww_hero_right {
  position: relative;
  width: 100%;
}

.canvawww_hero_title {
  position: absolute;
  top: 100px;
  color: #fff;
  font-size: 36px;
  font-weight: 600;
  width: 550px;
  padding-left: 10px;
}

.canvawww_hero_title span {
  font-size: 48px;
}

.canvawww_hero_text {
  position: absolute;
  top: 280px;
  width: 550px;
  color: #fff;
  font-size: 24px;
  background-color: #ff00ea05;
  padding: 10px;
  border-radius: 10px;
}

.canvawww_email_container {
  position: absolute;
  display: flex;
  /* width: 100%;
  max-width: 1200px; */
  top: 560px;
  left: 1%;
  width: 600px;
  padding: 20px;
  border-radius: 10px;
  background-color: #ffffff;
  height: 180px;
  flex-direction: column;
}

.canvawww_email_text {
  font-size: 24px;
}

video {
  width: 100%;
  height: 100%;
  object-fit: cover;
  /* z-index: 1000; */
}

.hero_grad {
  background-color: #92045930;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
}

/* ---------------------- Media Queries -------------------------- */

@media (max-width: 636px) {
  .canvawww_email_container {
    width: 94%;
  }
}

@media (max-width: 559px) {
  .hero_grad {
    background-color: #92045960;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
  }

  .canvawww_hero_container {
    position: relative;
    width: 100%;
    max-width: 1200px;
    z-index: 999999;
    margin: 0 auto;
  }

  .canvawww_hero_title {
    text-align: center;
    width: 90%;
    left: 4%;
  }

  .canvawww_hero_text {
    width: 90%;
    text-align: center;
    left: 4%;
  }
}

@media (max-width: 559px) {
  .canvawww_hero_title {
    width: 90%;
  }

  .canvawww_hero_text {
    width: 90%;
    font-size: 18px;
    top: 300px;
  }

  .canvawww_email_container {
    left: 3%;
  }
}

@media (max-width: 436px) {
  .canvawww_hero_text {
    font-size: 18px;
    top: 340px;
  }
}

/* ---------------------- Media Queries -------------------------- */
