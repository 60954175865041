.seller_type {
  display: flex;
  justify-content: center;
}

.seller_type_container {
  display: flex;
  flex-direction: column;
  text-align: center;
  background-color: #fff;
  border-radius: 10px;
  width: 440px;
  height: fit-content;
  padding: 20px;
  margin-top: 100px;
  box-shadow: 0 1px 2px var(--shadow-1);
}

.seller_type span {
  font-size: 20px;
  margin-bottom: 20px;
}

.seller_type_buttons {
  margin: 10px 0;
}

.seller_type_icon {
  background-color: #fff;
  border-radius: 50%;
  height: 34px;
  width: 34px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
  border: 2px solid var(--pri-ylw);
}
