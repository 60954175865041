.home {
  min-height: 100vh;
  display: flex;
  position: relative;
}

.home_middle {
  margin-top: 75px;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

.left_home {
  margin-top: 69px;
}

@media (max-width: 1175px) {
  .stories,
  .createPost,
  .post,
  .send_verification {
    width: 84%;
  }
  .home_middle {
    margin-left: 5%;
    width: 70%;
  }
  .right_home {
    width: 16vw;
  }
}

@media (max-width: 1030px) {
  .left_home .left_link span,
  .left_home .left_link .col,
  .shortcut,
  .shortcut_item span,
  .splitter,
  .nxxtstore_copyright {
    display: none;
  }
  .left_home {
    width: 15vw;
  }
  .left_link {
    background: var(--bg-third);
    width: 55px;
    height: 55px;
    border-radius: 50%;
    display: grid;
    place-items: center;
  }
  .home_middle {
    position: relative;
    margin-left: -5%;
  }
  .right_home {
    width: 16vw;
  }
  .contacts_header_right {
    right: 10%;
  }
  .contact_circle {
    width: 20px;
  }
  .stories,
  .createPost,
  .post,
  .send_verification {
    width: 100%;
  }
}
@media (max-width: 960px) {
  .createPost,
  .post,
  .send_verification {
    width: 93%;
  }
  .stories {
    width: 95%;
  }
  .right_home {
    left: 78%;
    width: 14vw;
  }
  .splitter1 {
    width: 20vw;
  }
  .contact_circle {
    width: 30px;
  }
  .contacts_header_right {
    right: 0;
  }
}
@media (max-width: 885px) {
  .right_home {
    display: none;
  }
  .createPost,
  .post,
  .send_verification {
    width: 110%;
  }
  .stories {
    width: 112%;
  }
  .home_middle {
    margin-left: 0%;
  }
}
@media (max-width: 805px) {
  .stories {
    grid-template-columns: repeat(6, 12.7vw);
  }
  .story_profile_name,
  .story_create_text {
    display: none;
  }
  .create_story_card {
    filter: brightness(96%);
  }
  .stories .white_circle {
    right: 5px;
  }
  .story_profile_pic {
    width: 7vw;
    height: 7vw;
  }
  .left_home::-webkit-scrollbar {
    display: none;
  }
  .popup {
    width: 70vw !important;
  }
}
@media (max-width: 620px) {
  .home_middle {
    position: absolute;
    margin-left: 3%;
  }
  .stories,
  .createPost,
  .post,
  .send_verification {
    width: 100%;
  }
  .stories .white_circle {
    display: none;
  }
  .stories {
    grid-template-columns: repeat(6, 14vw);
  }
  .story,
  .create_story_card {
    height: 30vw;
  }
  .createPost_icon {
    font-size: 12px;
  }
  .open_post {
    font-size: 16px;
  }
  .createPost_icon svg {
    width: 20px;
  }
}

@media (max-width: 600px) {
  .home {
    margin-top: 25px;
  }
}

@media (max-width: 400px) {
  .home {
    margin-top: 30px;
  }
  .createPost,
  .post {
    margin-top: 1rem;
  }
  .home_middle,
  .createPost,
  .post {
    width: 98%;
    margin: 1rem auto;
  }
  .send_verification {
    width: 98%;
    margin: 0 auto;
  }
  .stories {
    grid-template-columns: repeat(6, 13vw);
    padding-left: 10px;
  }
  .home_middle {
    margin: 56px auto;
  }
  .createPost_body {
    padding: 5px 0;
  }
}

/*-----Activate popup----*/
.popup {
  position: relative;
  background: var(--bg-primary);
  box-shadow: 0 1px 2px var(--shadow-1);
  width: 40vw;
  height: 200px;
  position: fixed;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 99;
  text-align: center;
  border-radius: 10px;
}
.popup_header {
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  border-bottom: 1px solid var(--bg-third);
  padding: 15px;
}
.popup_message {
  padding: 15px;
  font-weight: 500;
}
/*-----Activate popup----*/
