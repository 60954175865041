@import "https://unpkg.com/leaflet@1.9.3/dist/leaflet.css";
@import url("https://fonts.googleapis.com/css2?family=Caveat:wght@400;500;700&family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,100&family=Open+Sans:wght@300;400;500;600;700;800&family=Poppins:wght@100;200;300;400;500;600;700;800&family=Raleway:wght@100;200;300;400;500;600;700;800;900&family=Roboto:wght@100;300;400;500;700;900&display=swap");

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

body {
  font-family: "Segoe UI", Helvetica, Arial, sans-serif;
  scroll-behavior: smooth;
  user-select: none;
  background-color: var(--bg-blue);
}

html {
  overflow-y: scroll;
}

a {
  text-decoration: none;
  color: inherit;
}

.react-timerange-picker__wrapper {
  border: thin solid lightgray !important;
  border-radius: 5px;
  height: 38px;
  padding-left: 7px;
  color: gray;
}

.react-timerange-picker__clear-button {
  color: #b4b2b2 !important;
}

.react-timerange-picker__button:disabled .react-timerange-picker__button__icon {
  stroke: #b4b2b2 !important;
}

.react-time-picker__wrapper {
  display: flex !important;
  align-items: center !important;
  /* height: 100px; */
}

.react-time-picker__inputGroup__input {
  /* width: 100px; */
}

.react-time-picker__inputGroup__hour {
  width: 100px !important;
}

.react-time-picker__inputGroup__minute {
  width: 100px !important;
}

.react-time-picker__inputGroup__leadingZero {
  display: none !important;
}

.react-time-picker__clock .react-time-picker__clock--closed {
  display: none !important;
}

.react-time-picker__clear-button {
  margin-left: 10px !important;
  height: 20px !important;
}

.react-time-picker__inputGroup__divider {
  margin: 0px 3px !important;
}

:root {
  --pri-ylw: #fec93a;
  --pri-blue: #5e97b7;
  --bg-blue: #e9f3f7;

  --bg-secondary: #f0f2f5;
  --bg-third: #e4e6eb;
  --bg-forth: #f0f2f5;
  --color-primary: #050505;
  --color-secondary: #65676b;
  --divider: #ced0d4;
  --dark-bg-primary: #18191a;
  --dark-bg-secondary: #242526;
  --dark-bg-third: #3a3b3c;
  --dark-bg-forth: #3a3b3c;
  --dark-color-primary: #242526;
  --dark-color-secondary: #b0b3b8;
  --blue-color: #1876f2;
  --green-color: #66c553;
  --light-blue-color: #e7f3ff;
  --border-color: #ccced2;
  --shadow-1: rgba(0, 0, 0, 0.2);
  --shadow-2: rgba(0, 0, 0, 0.1);
  --shadow-3: rgba(0, 0, 0, 0.3);
  --shadow-inset: rgba(255, 255, 255, 0.5);
}

/*glassmorphism */
.white_glassmorphism {
  background: rgba(255, 255, 255, 0.07);
  border-radius: 16px;
  backdrop-filter: blur(40px);
  -webkit-backdrop-filter: blur(10px);
  border: 1px solid rgba(255, 255, 255, 0.3);
}

.blue_glassmorphism {
  background: rgb(39, 51, 89, 0.4);
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.2);
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(5px);
  border: 1px solid rgba(0, 0, 0, 0.3);
  /* border: 1px solid rgba(246, 242, 242, 0.3); */
  /* color: var(--bg-blue); */
}

.custom_glassmorphism {
  background: #ffffffe9;
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.2);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  border: 1px solid rgba(0, 0, 0, 0.3);
}

/* input: "w-full border p-1 rounded-[5px]", */
/* ------------Input---------- */
input {
  width: 100%;
  padding: 5px;
  border-radius: 5px;
  border: 1px solid var(--border-color);
}
/* ------------Input---------- */

/*----Buttons----*/
.black_button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 160px;
  height: 34px;
  color: #fff;
  background-color: #000;
  font-size: 18px;
  font-weight: 400;
  border-radius: 5px;
  padding: 5px;
  border: none;
  cursor: pointer;
}

.green_button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 160px;
  height: 34px;
  color: #fff;
  background-color: var(--green-color);
  font-size: 18px;
  font-weight: 400;
  border-radius: 5px;
  padding: 5px;
  border: none;
  cursor: pointer;
}

.blue_btn_2 {
  margin-top: 20px;
  border-radius: 5px;
  width: 200px;
  height: 40px;
  background-color: var(--pri-blue);
  font-size: 20px;
  font-weight: 400;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.blue_btn {
  border: none;
  outline: none;
  background: var(--pri-blue);
  padding: 8.5px 12px;
  border-radius: 7px;
  font-family: inherit;
  font-weight: 600;
  font-size: 14px;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 6px;
  cursor: pointer;
}

.blue_button_rev {
  border: none;
  outline: none;
  background: #fff;
  padding: 8.5px 12px;
  border-radius: 7px;
  font-family: inherit;
  font-weight: 600;
  font-size: 14px;
  color: var(--pri-blue);
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 6px;
  cursor: pointer;
  border: 1px solid var(--pri-blue);
}

.light_blue_btn {
  border: none;
  outline: none;
  background: var(--light-blue-color);
  padding: 8.5px 12px;
  border-radius: 7px;
  font-family: inherit;
  font-weight: 600;
  font-size: 14px;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 6px;
  cursor: pointer;
}
.gray_btn {
  border: none;
  outline: none;
  background: var(--bg-secondary);
  padding: 8.5px 12px;
  border-radius: 7px;
  font-family: inherit;
  font-weight: 600;
  font-size: 14px;
  color: var(--color-primary);
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 6px;
  cursor: pointer;
}

.blue_btn,
.gray_btn span {
  transform: translateY(-1px);
}

.pdc_cart_button {
  margin-top: 20px;
  border-radius: 5px;
  width: 200px;
  height: 40px;
  background-color: red;
  font-size: 20px;
  font-weight: 400;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.batch_heart {
  background: tomato;
  width: 15px;
  height: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  position: absolute;
  right: -24%;
  top: -9%;
  cursor: pointer;
}

/*----Buttons----*/
.blur {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  /* background: rgba(255, 255, 255, 0.768); */
  background: #e9f3f790;
  bottom: 0;
  z-index: 9999999999;
}

.hidden {
  display: none;
}

/*----Buttons----*/

/*-----Hovers-----*/
.hover0:hover {
  background: #ffffff90;
  color: var(--pri-blue);
  border-radius: 5px;
}
.hover1:hover {
  background: var(--bg-secondary);
}
.hover2:hover {
  background: var(--bg-third);
}
.hover3:hover {
  background: #d4d6da3a;
}

/*-----Hovers-----*/
.card {
  height: 500px;
  width: 500px;
  background: red;
}

/*----Scrollbar----*/

.scrollbar::-webkit-scrollbar {
  width: 8px;
}
.scrollbar::-webkit-scrollbar-thumb {
  background: #aeaeafa1;
  border-radius: 5px;
}
/*----Scrollbar----*/

/*---Circles------*/
.small_circle {
  position: relative;
  height: 36px;
  width: 36px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 11px;
  background: var(--bg-third);
  cursor: pointer;
}
/*---Circles------*/

/*----filters---*/
.filter_blue {
  filter: invert(39%) sepia(57%) saturate(200%) saturate(200%) saturate(200%)
    saturate(200%) saturate(200%) saturate(147.75%) hue-rotate(202deg)
    brightness(97%) contrast(96%);
}
/*----filters---*/

/* ------ section ----- */
section {
  width: 100%;
  max-width: 1400px;
  margin: 0px auto;
  display: flex;
  flex-direction: column;
}

.section_header {
  margin: 0px 0px 20px 10px;
  font-size: 2rem;
}

.section_container {
  display: flex;
  flex-wrap: wrap;
}

@media screen and (max-width: 1400px) {
  section {
    width: 95%;
  }
  .section_container {
    justify-content: space-between;
  }
}

@media (max-width: 1270px) {
  .section_container {
    justify-content: center;
  }
}
@media (max-width: 1208px) {
  .section_container {
    justify-content: center;
  }
}

@media (max-width: 1024px) {
  .section_container {
    justify-content: center;
  }

  .section_header {
    text-align: center;
  }
}

@media screen and (max-width: 802px) {
  .section_header {
    text-align: center;
  }
}
/* ------ section ----- */
