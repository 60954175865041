/* .shop_profile_page {
  width: 100%;
  display: flex;
} */

.shop_profile {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.shop_profile_top {
  box-shadow: 0 1px 2px var(--shadow-1);
  background: #ffffff50;
  width: 100%;
  height: 640px;
  margin: 49px auto 0 auto;
}

.shop_profile_container {
  max-width: 1400px;
  width: 100%;
  margin: 0 auto;
  justify-content: center;
}

.shop_profile_cover {
  position: relative;
  height: 480px;
  width: 100%;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  background: var(--bg-secondary);
}

.shop_cover {
  width: 100%;
  height: 480px;
  object-fit: cover;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}

.udpate_shop_cover_wrapper {
  position: absolute;
  bottom: 1rem;
  right: 1rem;
}

.open_shop_cover_update {
  background: #fff;
  padding: 7px 15px;
  display: flex;
  align-items: center;
  gap: 10px;
  font-weight: 600;
  font-size: 14px;
  color: #111;
  border-radius: 10px;
  cursor: pointer;
}

.open_shop_cover_update i {
  transform: scale(0.9);
  margin-top: 4px;
}

.open_shop_cover_menu {
  background: var(--bg-primary);
  position: absolute;
  right: 0;
  padding: 10px;
  width: 300px;
  border-radius: 10px;
  box-shadow: 0 12px 28px 0 var(--shadow-1), 0 2px 4px 0 var(--shadow-2),
    inset 0 0 0 px var(--shadow-inset);
  z-index: 99999;
}

.open_shop_cover_menu_item {
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 10px;
  cursor: pointer;
  font-weight: 600;
  font-size: 14px;
  border-radius: 10px;
}

.shop_profile_img_wrap {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 1rem;
  margin: 0rem auto -2.8rem auto;
  width: 100%;
  max-width: 1200px;
}

.shop_profile_w_left {
  display: flex;
  gap: 1rem;
  padding: 1rem 13px 0 13px;
}

.shop_profile_w_img {
  position: relative;
  z-index: 9999;
}

.shop_profile_w_bg {
  width: 120px;
  height: 120px;
  transform: translateY(-2.6rem);
  border-radius: 10px;
  background-repeat: no-repeat;
  border: 2px solid #fff;
  background: #fff;
  cursor: pointer;
}
.shop_profile_w_bg:hover {
  filter: brightness(95%);
}
.shop_profile_circle {
  position: absolute;
  bottom: 4.5rem;
  right: 0.55rem;
  background: var(--bg-third);
  width: 36px;
  height: 36px;
  border-radius: 10px;
  display: grid;
  place-items: center;
  cursor: pointer;
}
.shop_profile_name {
  display: flex;
  align-items: center;
  gap: 10px;
  font-weight: 700;
  font-size: 32px;
}
.shop_profile_w_right {
  display: none;
  gap: 8px;
  padding: 0 18px;
  margin-bottom: 7px;
}
.invert {
  filter: invert(100%);
}

.shop_profile_about {
  background: #fff;
  border-radius: 10px;
  box-shadow: 0 1px 2px var(--shadow-1);
  padding: 10px 15px 10px 40px;
  width: 20%;
}

.shop_user_profile_image_wrap {
  position: absolute;
  top: -35px;
  left: 136px;
  cursor: pointer;
  z-index: 11111;
}

.shop_user_profile_image {
  height: 30px;
  width: 30px;
  border-radius: 50%;
  border: 2px solid #fff;
  object-fit: cover;
}

/*--Menu-------*/
.shop_profile_menu_wrap {
  position: relative;
  padding: 0 2.2rem;
}

.shop_profile_menu {
  position: relative;
  border-top: 1px solid var(--border-color);
  display: flex;
  padding: 0;
  justify-content: center;
}
.shop_profile_menu a {
  height: 34px;
  width: 78.5px;
  font-weight: 600;
  font-size: 14px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 20px;
  background-color: var(--pri-blue);
  color: #fff;
  border: 1px solid var(--pri-blue);
  margin: 5px 20px;
}

.shop_profile_menu_active {
  height: 60px !important;
  width: 65px !important;
  margin: 0 !important;
  color: var(--blue-color) !important;
  border-bottom: 3px solid var(--blue-color);
  border-radius: 0 !important;
}

.save_shop_changes_cover {
  z-index: 999999999;
  position: fixed;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 10px 15px;
  background: rgba(0, 0, 0, 0.7);
  font-weight: 500;
  font-size: 14px;
  color: var(--color-primary);
}
.save_shop_changes_left {
  display: flex;
  align-items: center;
  gap: 10px;
  color: #fff;
}
.save_shop_changes_left i {
  filter: invert(100%);
}
.save_shop_changes_right {
  display: flex;
  align-items: center;
  gap: 10px;
}
.save_shop_changes_right button {
  padding: 10px;
}
.save_shop_changes_right button:first-of-type {
  width: 100px;
  background: #39393f;
}
.save_shop_changes_right button:last-of-type {
  width: 160px;
}
.selectCoverBox {
  width: 570px !important;
  max-width: 80vw;
}
.selectCoverBox_links {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  padding: 5px 15px;
}
.selectCoverBox_link {
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  font-size: 14px;
  padding: 5px 0 10px 0;
  cursor: pointer;
}
.selectCoverBox_link:first-of-type {
  color: var(--blue-color);
  border-bottom: 3px solid var(--blue-color);
}

/* ---------------- shop_product_data ----------------------------*/
.shop_profile_data {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 100px;
  align-items: center;
}

.shop_profile_profile {
  position: absolute;
  right: -80px;
  display: flex;
  width: 100%;
  min-width: 600px;
  justify-content: center;
}

.spd_tab_profile {
  display: flex;
  justify-content: space-between;
  margin: 0px 20px;
}

.spd_container_profile {
  position: relative;
  width: 100%;
  background-color: blue;
}

.spd_container {
  width: 100%;
  display: flex;
}

.spd_top {
  display: flex;
  width: 100%;
  justify-content: center;
}

.spd_tab {
  display: flex;
  align-items: center;
  margin: 0 30px;
}

.spd_text {
  font-size: 20px;
  font-weight: 400;
  cursor: pointer;
}

.shop_profile_data_active {
  color: var(--pri-blue);
}

.spd_bottom_container {
  display: flex;
  flex-wrap: wrap;
  max-width: 1200px;
  justify-content: flex-start;
}

/* ---------------- shop_product_data ----------------------------*/
