.cart_container {
  position: fixed;
  top: 50px;
  right: 0px;
  min-height: 90vh;
  padding: 10px;
  width: 24%;
  background: #fff;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  box-shadow: 0px 5px 15px gray;
  border-radius: 10px;
  z-index: 999999;
}

.cart_header {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: flex-end;
  padding-top: 5px;
  padding-right: 5px;
}

.cart_close_icon {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 10px;
}

.cart_single_container {
  position: absolute;
  top: 100px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 90%;
  margin-left: 3%;
}

.cart_single {
  position: relative;
  border: 1px solid lightgray;
  margin: 20px 10px;
  padding: 10px;
  border-radius: 10px;
  height: 140px;
  height: 160px;
}

.cart_single_wrap {
  position: relative;
  display: flex;
  width: 100%;
  align-items: center;
  border: lightgray;
  align-items: center;
  height: 100px;
}

.cart_single_left {
  position: absolute;
  top: 22px;
  display: flex;
  width: 40%;
  align-items: center;
}

.cart_single_right {
  position: absolute;
  display: flex;
  width: 30%;
  left: 45%;
  top: 20px;
  margin-left: 10px;
}

.cart_text {
  position: relative;
  width: 100%;
}

.cart_name {
  color: black;
  font-size: 14px;
  margin-bottom: 10px;
  width: 140px;
}

.cart_price {
  font-weight: 400;
  font-size: 14px;
  margin-bottom: 10px;
}

.cart_image {
  height: 90px;
  width: 65%;
  margin-left: 8px;
  margin-right: 8px;
  border-radius: 5px;
}

.cart_actions {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.cart_delete {
  position: absolute;
  right: 2%;
  top: 0;
}

.cart_plus {
  background: #e44343;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20px;
  height: 20px;
  border-radius: 50%;
}

.cart_minus {
  background: black;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20px;
  height: 20px;
  border-radius: 50%;
}

.cart_total {
  font-weight: 600;
  font-size: 16px;
  padding-top: 3px;
  color: #d02222;
  font-family: Roboto;
}

.cart_button {
  height: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  background: red;
  border-radius: 5px;
}

.cart_button_text {
  color: #fff;
  font-size: 18px;
  font-weight: 600;
}

.cart_delivery {
  display: flex;
  align-items: center;
  border: 1px solid lightgray;
  padding: 10px;
  border-radius: 10px;
  width: 95%;
  margin-left: 3%;
}

.cart_delivery span {
  margin-left: 10px;
}

@media (max-width: 1520px) {
  .cart_container {
    width: 25%;
  }
  .cart_single {
    height: 140px;
  }
  .cart_single_left {
    width: 55%;
    top: 20px;
  }

  .cart_single_right {
    width: 40%;
    left: 45%;
    /* top: 0px; */
    margin-left: 5px;
  }

  .cart_image {
    height: 70px;
    width: 55%;
  }

  .cart_name {
    margin-bottom: 5px;
  }

  .cart_price {
    margin-bottom: 5px;
  }
}

@media (max-width: 1274px) {
  .cart_single_container {
    margin-left: 0%;
  }
  .cart_single {
    width: 98%;
  }
  .cart_single_left {
    width: 45%;
    top: 20px;
  }
  .cart_single_right {
    width: 40%;
    left: 45%;
    margin-left: -5px;
  }
}
@media (max-width: 1120px) {
  .cart_container {
    width: 30%;
  }
}

@media (max-width: 880px) {
  .cart_container {
    width: 35%;
  }
}

@media (max-width: 760px) {
  .cart_container {
    width: 40%;
  }
}

@media (max-width: 660px) {
  .cart_container {
    width: 45%;
  }
}

@media (max-width: 600px) {
  .cart_header {
    top: 45px;
  }
  .cart_close_icon {
    position: absolute;
    top: 5px;
    right: 10px;
    /* margin-top: 10px; */
  }
}

@media (max-width: 560px) {
  .cart_container {
    width: 50%;
  }
}

@media (max-width: 490px) {
  .cart_container {
    width: 60%;
  }
}

@media (max-width: 420px) {
  .cart_container {
    width: 70%;
  }
}

@media (max-width: 380px) {
  .cart_container {
    width: 80%;
  }
}
