.feed {
  height: calc(100vh - 56px);
  width: 100%;
  margin-top: 56px;
  display: grid;
  color: var(--color-primary);
}
.feed_left {
  background: var(--bg-primary);
  box-shadow: 0 12px 12px var(--shadow-1);
  padding: 10px;
  position: fixed;
  top: 49px;
  width: 360px;
  height: 100%;
  background-color: red;
}

.feed_left_wrap {
  width: 15vw;
}

.feed_right {
  background: #fff;
  padding: 2rem;
  position: absolute;
  top: 56px;
  left: 360px;
  right: 0;
  width: calc(100% - 360px);
  min-height: calc(100vh - 56px);
}

.feed_left_header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.feed_left_header h3 {
  font-size: 21px;
}

.feed_left .mmenu_item {
  cursor: pointer;
}

.active_feed {
  color: var(--pri-blue);
}

.mmenu_item .small_circle {
  background-color: #fff;
}

.active_feed i {
  filter: invert(100%);
}

.mmenu_item .rArrow {
  display: block;
}

.see_link {
  color: var(--blue-color);
  padding: 5px;
  cursor: pointer;
}
.feed_right_wrap {
  padding-bottom: 10px;
  border-bottom: 1px solid var(--bg-third);
}

.feed_right_wrap .flex_wrap {
  margin-top: 10px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 15px;
}
.req_card {
  background: var(--bg-primary);
  box-shadow: 1px 1px 1px var(--shadow-1);
  width: 210px;
  height: fit-content;
  padding-bottom: 10px;
  border-radius: 10px;
  font-weight: 600;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
}
.req_card img {
  width: 100%;
  height: 200px;
  object-fit: cover;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}
.req_card button {
  width: 170px !important;
}
.feed_item_container {
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 1px 2px var(--shadow-1);
  margin-top: 1.1rem;
  margin-bottom: 1.1rem;
  width: 685px;
  cursor: pointer;
}

.address_modal {
  position: fixed;
  top: 0;
  left: 0;
  align-items: center;
  justify-content: center;
  width: 685px;
  height: 100vh;
  background: #ffffff99;
  z-index: 1000;
  display: flex;
}

.address_modal_container {
  position: relative;
  width: 100%;
  height: 50vh;
  /* background: #fff; */
  background-color: #fff;
  border-radius: 20px;
  box-shadow: 5px 10px 15px var(--shadow-1);
  overscroll-behavior-y: auto;
  border: 1px solid var(--border-color);
  padding: 20px;
}

.address_modal_header {
  display: flex;
  width: 100%;
  justify-content: end;
  padding: 10px;
}

.address_modal_title {
  text-align: center;
  font-size: 24px;
}

@media (max-width: 1184px) {
  .feed_left_wrap .mmenu_item span {
    font-size: 12px;
  }
}

@media (max-width: 1026px) {
  .feed_left_wrap .mmenu_item span {
    display: none;
  }
  .mmenu_item .rArrow {
    left: 45px;
  }
}

@media (max-width: 870px) {
  .feed_left {
    width: 100px;
  }
  .feed_right {
    left: 100px;
    width: calc(100% - 100px);
  }
  .feed_left .feed_left_header .small_circle {
    display: none;
  }
  .feed_left .mmenu_item span {
    display: none;
  }
}
@media (max-width: 480px) {
  .mmenu_item .rArrow {
    display: none;
  }
}
@media (max-width: 400px) {
  .feed_left_header h3 {
    font-size: 16px;
  }
  .see_link {
    font-size: 12px;
    width: 50px;
  }

  .feed_right {
    padding: 10px;
  }
}
