header {
  position: fixed;
  top: 0;
  height: 48px;
  display: flex;
  align-items: center;
  width: 100%;
  z-index: 9999999999999;
  justify-content: center;
  background-color: #fff;
  box-shadow: 1px 8px 15px -7px var(--shadow-2);
}
.am_header {
  position: fixed;
  top: 0;
  height: 48px;
  display: flex;
  align-items: center;
  width: 100%;
  z-index: 9999999999999;
  justify-content: center;
  /* background-color: #0c0c09; */
  background-color: #070707;
  /* background-color: #000; */
  /* box-shadow: 1px 8px 15px -7px var(--shadow-2); */
}

.canvawww_header {
  position: fixed;
  top: 0;
  height: 48px;
  display: flex;
  align-items: center;
  width: 100%;
  z-index: 9999999999999;
  justify-content: center;
  background-color: transparent;
  box-shadow: none;
}

.nav {
  display: flex;
  position: relative;
  align-items: center;
  margin: 0 auto;
  width: 100%;
  max-width: 1400px;
  justify-content: space-between;
}

/* .header {
  height: 48px;
  display: flex;
  background: transparent;
  width: 100%;
  margin: 0 auto;
} */

/* .header_bg {
  background-color: rgb(255, 255, 255);
  color: var(--pri-clr);
  border-bottom: 1px solid var(--sec-blue);
  box-shadow: 1px 8px 15px -7px var(--shadow-2);
  margin: 0px auto;
} */

/*---Header left-----*/
.header_left {
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 5px 0px;
  position: absolute;
  top: -23px;
  left: 10px;
}

.header_logo {
  width: 36px;
  height: 36px;
}

.header_logo_text {
  /* font-weight: 400; */
  color: var(--pri-blue);
}

.header_middle .circle {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}
.search {
  display: flex;
  align-items: center;
  gap: 8px;
  background: var(--bg-forth);
  padding: 10px 32px 10px 10px;
  border-radius: 50px;
  cursor: text;
  border: 1px solid #b0b3b8;
}
.search input {
  outline: none;
  border: none;
  background: transparent;
  font-size: 15px;
  font-family: inherit;
}
.search input::placeholder {
  transform: translateY(-1px);
}

.search_type_text {
  display: flex;
  color: #a4a9b1;
  font-size: 10px;
}
/*---Header left-----*/

/*---Header middle-----*/

/* .header_middle {
  display: flex;
  align-items: center;
  margin: 0% 0% 0% 10%;
  gap: 10px;
  transform: translateX(3px);
} */

.header_middle_none {
  display: none;
}
.middle_icon {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 105px;
  height: 48px;
  border-radius: 10px;
  cursor: pointer;
  transform: translateX(-2px);
}
.middle_icon:nth-child(2) {
  transform: translateX(-4px);
}
.middle_icon:nth-child(3) {
  transform: translateX(-3px);
}
.middle_icon:nth-child(4) {
  transform: translateX(-5px);
}
.middle_icon:nth-child(5) {
  transform: translateX(-5px);
}

.header_active {
  border-bottom: 3px solid black;
  color: var(--pri-blue);
  border-radius: 0;
  height: 50px;
  transform: translateX(0);
}
.active svg {
  transform: translateY(1px) translateX(-1px);
}

.middle_notification {
  position: absolute;
  top: 3px;
  right: 1.9rem;
  background: #e41e3f;
  border-radius: 50px;
  padding: 1px 5px;
  font-size: 13px;
  color: #fff;
}
/*---Header middle-----*/

/*---Header right-----*/

.header_right {
  display: flex;
  position: absolute;
  right: 20px;
  top: 50%;
  transform: translateY(-50%);
}

.am_header_right {
  display: flex;
  position: absolute;
  right: 20px;
  top: 50%;
  transform: translateY(-50%);
}

.profile_link {
  display: flex;
  align-items: center;
  gap: 5px;
  cursor: pointer;
  padding: 3px 10px 3px 6px;
  border-radius: 50%;
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  margin-right: 10px;
}

.profile_link img {
  width: 28.7px;
  height: 28.7px;
  border-radius: 50%;
  border: 1px solid #b0b3b8;
  transform: translateX(-1px);
}
.profile_link span {
  margin-bottom: 3px;
}

/* .profile_name {
  display: block;
  font-weight: 400;
  font-size: 8px;
  color: red;
} */

.circle_icon {
  position: relative;
  height: 40px;
  width: 40px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 8px;
  /* background: var(--bg-third); */
  cursor: pointer;
}
.right_notification {
  position: absolute;
  top: -6px;
  right: -5px;
  background: #e41e3f;
  border-radius: 50px;
  padding: 1px 6px;
  font-size: 13px;
  color: #fff;
}

.header_sign {
  font-weight: 700;
  font-size: 18px;
  display: flex;
  position: absolute;
  right: 20px;
  top: -10px;
}

.header_sign_text {
  /* padding: 0px 20px 20px 0px; */
}

.header_seller_button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 150px;
  height: 34px;
  color: #fff;
  background-color: #000;
  font-size: 16px;
  font-weight: 400;
  border-radius: 5px;
  padding: 5px;
}

.canvawww_header_seller_button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 150px;
  height: 34px;
  color: #fa00ab;
  background-color: #fff;
  font-size: 16px;
  font-weight: 400;
  border-radius: 5px;
  padding: 5px;
}

.mmenu_seller_button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 150px;
  height: 34px;
  color: #fff;
  background-color: #000;
  font-size: 16px;
  font-weight: 400;
  border-radius: 5px;
  padding: 5px;
}

/* .header_seller_arrow {
  display: block;
} */

/*---Header right-----*/

/*---Search menu-----*/
.search_area {
  position: absolute;
  top: 1px;
  left: 140px;
  align-items: flex-start;
  flex-direction: column;
  gap: 6px;
  background: #fff;
  box-shadow: 0 12px 12px var(--shadow-1), inset 0 0 0 0 var(--shadow-inset);
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  transition: all 0.2s;
  -webkit-font-smoothing: antialiased;
  z-index: 1;
  min-height: 400px;
  max-height: 70vh;
  padding: 8px 16px 10px 6px;
}
.search_wrap {
  display: flex;
  align-items: center;
  gap: 6px;
}
.search_area .search {
  padding: 10px 44px 10px 12px;
  width: 252px;
  gap: 4px;
}
.search_area .search input {
  padding-left: 4px;
  color: var(--color-primary);
}
.search_area .search svg {
  transform: translateY(1px);
}
.search_area .search input::placeholder {
  transform: translateY(-2px);
}
.search_area .search input:focus::placeholder {
  transform: translateY(-1px);
}
.search_history {
  width: 100%;
}
.search_history_header {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  font-size: 14px;
}
.search_history_header span {
  font-weight: 600;
  font-size: 16px;
}
.search_history_header a {
  cursor: pointer;
  color: var(--blue-color);
}

/*---Search menu-----*/
/*---Search All Menu-----*/
.all_menu {
  position: absolute;
  right: -9rem;
  top: 45px;
  background: var(--bg-secondary);
  border-radius: 10px;
  height: 90vh;
  width: 575px;
  padding: 10px 1rem;
  box-shadow: -2px 1px 5px 2px var(--shadow-1);
  user-select: none;
}
.all_menu_header {
  font-size: 24px;
  line-height: 28px;
  font-weight: 700;
  margin-bottom: 10px;
}
.all_menu_wrap {
  display: grid;
  grid-template-columns: 1.7fr 1fr;
  gap: 1rem;
  overflow-y: auto;
  height: 95%;
}

.all_left {
  background: var(--bg-primary);

  border-radius: 10px;
  padding: 15px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
}

.all_menu_search {
  background: var(--bg-secondary);

  display: flex;
  align-items: center;
  gap: 5px;
  padding: 10px;
  border-radius: 50px;
}
.all_menu_search input {
  border: none;
  outline: none;
  background: transparent;
  width: 100%;
  font-size: 15px;
}
.all_menu_group {
  margin-top: 10px;
  border-bottom: 1px solid #ced0d4;
}
.all_menu_group_header {
  font-weight: 600;
  font-size: 16px;
  padding: 10px 0;
}
.all_menu_group {
  width: 100%;
}
.all_menu_item {
  display: flex;
  align-items: center;
  width: 100%;
  margin-bottom: 5px;
  padding: 5px;
  cursor: pointer;
  border-radius: 10px;
}

.all_menu_item img {
  width: 36px;
  height: 36px;
  margin-right: 10px;
}
.all_menu_col {
  display: flex;
  flex-direction: column;
}
.all_menu_col span:first-of-type {
  font-size: 14px;
  font-weight: 500;
}
.all_menu_col span:last-of-type {
  font-size: 12px;
  color: #65676b;
  width: 95%;
}
.all_right {
  padding: 15px 10px;
  background: var(--bg-primary);
  margin-right: 1rem;
  border-radius: 10px;
  height: 550px;
  width: 170px;
  position: fixed;
  right: 2rem;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
}
.all_right_header {
  font-size: 20px;
  font-weight: 700;
  margin-bottom: 15px;
}

.all_right_item {
  display: flex;
  align-items: center;
  font-weight: 600;
  gap: 10px;
  font-size: 14px;
  cursor: pointer;
  padding: 5px 10px;
  border-radius: 10px;
  margin-bottom: 2px;
}

.all_right_circle {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--bg-third);
}
/*---Search All Menu-----*/

/*-------User menu----*/

.mmenu {
  padding: 0 0.3rem;
  position: absolute;
  top: 100%;
  right: 0;
  width: 360px;
  border-radius: 10px;
  background: #fff;
  box-shadow: 2px 2px 2px var(--shadow-1);
  user-select: none;
  padding: 10px;
}
.mmenu_header {
  display: flex;
  align-items: center;
  padding: 5px;
  border-radius: 10px;
  gap: 10px;
}
.mmenu_header img {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  object-fit: cover;
}
.mmenu_col {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.mmenu_col span:first-of-type {
  color: var(--color-primary);
  font-weight: 600;
  font-size: 15px;
  letter-spacing: 1px;
}
.mmenu_col span:last-of-type {
  font-size: 14px;
}
.mmenu_main {
  padding: 10px;
  margin-top: 5px;
  display: flex;
  align-items: center;
  gap: 5px;
}
.mmenu_splitter {
  width: 100%;
  height: 1px;
  background: var(--bg-third);
  margin-top: 5px;
}
.mmenu_span1 {
  font-size: 14px !important;
}
.mmenu_span2 {
  font-size: 12px !important;
  color: var(--color-secondary);
}
.mmenu_item {
  position: relative;
  display: flex;
  align-items: center;
  margin-top: 5px;
  padding: 7px;
  font-weight: 600;
  font-size: 14px;
  border-radius: 10px;
}
.rArrow {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
}

.absolute_wrap {
  padding: 0 0.3rem;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  width: 360px;
  height: fit-content;
  border-radius: 10px;
  background: var(--bg-primary);
  box-shadow: 2px 2px 2px var(--shadow-1);
  user-select: none;
  padding: 10px;
}
.absolute_wrap .mmenu_item {
  margin-top: 0;
}
.absolute_wrap_header {
  display: flex;
  align-items: center;
  gap: 10px;
  font-weight: 700;
  font-size: 24px;
}

.absolute_wrap label {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-left: 50px;
  font-weight: 600;
  padding: 10px;
  cursor: pointer;
  border-radius: 10px;
}
.absolute_wrap label input {
  width: 20px;
  height: 20px;
}
.search_user_item i {
  position: absolute;
  right: 10px;
  transform: scale(0.6);
}

/*-------User menu----*/
.search_results {
  width: 100%;
  max-height: 70vh;
  overflow-y: auto;
}
.search_history {
  width: 100%;
  max-height: 70vh;
  overflow-y: auto;
}
.search_user_item {
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 5px;
  border-radius: 10px;
  cursor: pointer;
}
.search_user_item img {
  width: 36px;
  height: 36px;
  border-radius: 50%;
  object-fit: cover;
}
.search_user_item span {
  font-weight: 600;
  font-size: 14px;
}

/*------------ Media Queries ---------------*/
@media (max-width: 1295px) {
  .middle_icon {
    width: 90px;
  }
}

@media (max-width: 1130px) {
  .middle_icon {
    width: 70px;
  }
}

@media (max-width: 1040px) {
  .search1 {
    width: 40px;
    height: 40px;
    padding: 0;
    justify-content: center;
  }
  .hide_input {
    display: none;
  }
  .header_middle {
    transform: translateX(-15%);
  }
}

@media (max-width: 945px) {
  .header_middle {
    transform: translateX(-22%);
  }
}

@media (max-width: 870px) {
  .header_middle {
    transform: translateX(-30%);
  }

  .middle_icon:nth-child(5) {
    display: none;
  }
}

@media (max-width: 790px) {
  .header_middle {
    transform: translateX(-37%);
  }

  .header_right {
    right: 20px;
  }

  .middle_icon:nth-child(4) {
    display: none;
  }
}

@media (max-width: 710px) {
  .middle_icon:nth-child(2) {
    display: none;
  }
  .middle_icon:nth-child(3) {
    display: none;
  }
}

@media (max-width: 670px) {
  .profile_link span {
    display: none;
  }
}

@media screen and (max-width: 640px) {
  .wishlist {
    display: none;
  }
}

@media (max-width: 620px) {
  .all_menu {
    width: 485px;
  }
  .all_left {
    width: 250px;
  }
}
@media screen and (max-width: 600px) {
  .search {
    position: absolute;
    top: 55px;
  }

  .search_area {
    top: 55px;
    left: 60px;
    width: 340px;
    border-radius: 10px;
  }
  .profile_link {
    position: absolute;
    padding: 0px;
    top: 52px;
    right: -16px;
    background-color: none;
  }

  .profile_link img {
    width: 40px;
    height: 40px;
    opacity: 0.8;
  }
}

@media (max-width: 570px) {
  /* .middle_icon:nth-child(1) {
    display: none;
  } */

  .header_middle {
    transform: translateX(-20%);
  }
}

@media (max-width: 505px) {
  .all_menu {
    width: 340px;
    right: -9rem;
  }
  .all_menu_wrap {
    grid-template-columns: 1fr;
  }
  .all_left {
    width: 100%;
  }
  .all_right {
    position: relative;
    width: 100%;
    padding-left: 3rem;
    margin-bottom: 1rem;
  }
}

@media (max-width: 418px) {
  .header_right {
    align-items: center;
  }
  .header_seller_button {
    width: 30px;
    height: 30px;
    font-size: 12px;
    border-radius: 50%;
    padding-bottom: 7px;
  }
  .canvawww_header_seller_button {
    display: none;
  }
  .header_seller_arrow {
    display: none;
  }
}

@media (max-width: 400px) {
  .header_right .circle_icon:nth-child(4) {
    display: none;
  }
  .mmenu {
    width: 290px;
  }
  .all_menu {
    width: 310px;
    left: -10rem;
  }
  .search_area {
    width: 310px;
  }
}

@media screen and (max-width: 360px) {
  .header_right_block {
    display: none;
  }
}

/*------------ Media Queries ---------------*/
/*----Active header----*/
.active_header {
  background: var(--light-blue-color);
}
.active_header svg {
  fill: black;
}
.active_link {
  background: var(--light-blue-color);
  color: var(--blue-color);
}
/*----Active header----*/
