.footer {
  width: 100%;
  height: 350px;
  /* width: 1024px; */
  font-size: 12px;
  margin: 0px auto 0px auto;
  background-color: var(--pri-blue);
  display: flex;
  justify-content: center;
  position: relative;
  padding-top: 50px;
}

.footer-lists {
  width: 100%;
  max-width: 1024px;
  display: flex;
  justify-content: space-between;
  margin-bottom: 50px;
}

.footer-list {
  list-style: none;
  padding: 0;
}

.footer-list_item {
  margin-bottom: 10px;
  color: white;
  cursor: pointer;
}

.footer-text {
  position: absolute;
  display: flex;
  margin: 0 auto;
  color: white;
  bottom: 20px;
}

.footer_sell_btn {
  font-size: 18px;
  border: 1px solid #fff;
  border-radius: 15px;
  padding: 3px 10px;
  width: 100px;
  display: flex;
  justify-content: center;
}

/* ----------- media queries --------------- */

@media screen and (max-width: 1058px) {
  .footer-lists {
    width: 96%;
  }
}
/* ----------- media queries --------------- */
