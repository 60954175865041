/*------- Shop login-----*/
.shop_login {
  height: 100vh;
  width: 100%;
  margin-top: 50px;
}

.shop_login_wrapper {
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  position: relative;
}

.shop_login_wrap {
  height: 90vh;
  width: 100%;

  background-repeat: no-repeat;
  object-fit: cover;
  color: var(--color-primary);
  display: flex;
}
.shop_login_1 {
  width: 300px;
  margin: 0 auto;
}
.shop_login_1 span {
  font-size: 20px;
}
.shop_login_2 {
  text-align: center;
}
.shop_login_2_wrap {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  background: #fff;
  box-shadow: 0 1px 2px var(--shadow-1);
  padding: 2rem;
  padding-bottom: 2rem;
  width: 400px;
  height: fit-content;
  margin: 1rem auto;
  user-select: none;
  border-radius: 10px;
}
.shop_login_2_wrap button {
  width: 100%;
  height: 50px;
  font-size: 17px;
  font-weight: 700;
}
.forgot_password {
  color: var(--blue-color);
  font-size: 14px;
  cursor: pointer;
}
.forgot_password:hover {
  text-decoration: underline;
}
.sign_splitter {
  width: 100%;
  height: 1px;
  background: var(--bg-third);
}
.open_signup {
  background: var(--green-color);
  width: 70% !important;
  font-weight: 600 !important;
  font-size: 17px !important;
  margin-top: 1rem;
}
.sign_extra {
  font-size: 15px;
}
/*-------shop_login-----*/
