.featured_product {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 430px;
  margin: 60px auto 0px auto;
}

.fp_container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;
  height: 340px;
}

@media (max-width: 1010px) {
  .fp_container div:nth-child(5) {
    display: none;
  }
}

@media (max-width: 606px) {
  .fp_container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;
    height: 840px;
  }
}
