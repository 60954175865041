.product_page {
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  margin-top: 50px;
}

.product_page_best_deals {
  margin-top: 0px;
}

.product_page_all_products {
  display: flex;
  flex-wrap: wrap;
  margin-top: 40px;
}

/* ---------------- .suggested_products -------------------- */

.suggested_products {
  width: 100%;
}

.suggested_products_container {
  margin: 0px auto;
  max-width: 1400px;
  padding: 40px;
}

/* ---------------- .suggested_products -------------------- */

@media (max-width: 608px) {
  .product_page_best_deals {
    margin-top: 380px;
  }

  .product_page_all_products {
    margin-top: 40px;
  }
}

@media (max-width: 474px) {
  .product_page_best_deals {
    margin-top: 360px;
  }
}

@media (max-width: 474px) {
  .product_page_best_deals {
    margin-top: 280px;
  }
}
