.service_card {
  display: flex;
  flex-direction: column;
  width: 324px;
  height: 400px;
  box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.1);
  position: relative;
  background: #fff;
  margin: 0px 0px 20px 0px;
}

.service_image {
  width: 100%;
  height: 200px;
  object-fit: cover;
}

.service_servicer_info {
  display: flex;
}

.service_pp {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  margin-right: 5px;
}

.service_name {
  display: flex;
  justify-content: flex-end;
  font-weight: 400;
}

.service_shop_name {
  font-size: 14px;
  font-weight: 300;
  color: var(--blue-color);
  margin-bottom: 5px;
}

.service_details {
  padding: 5px;
  height: 110px;
}

.service_name_slice {
  padding-bottom: 3px;
  font-weight: 300;
  font-size: 20px;
}

.service_description {
  font-size: 14px;
  font-weight: 400;
  color: gray;
}

.service_rating {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0px 5px;
}

.service_star_wrap {
  display: flex;
  width: 70%;
}

.service_star {
  margin-right: 2px;
  cursor: pointer;
  margin: 10px 0px;
}

.service_icon {
  cursor: pointer;
  position: absolute;
  right: 8px;
  top: 20px;
}

.service_certs {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.service_timings {
  position: absolute;
  bottom: 80px;
}

.service_card hr {
  height: 0;
  border: 0.5px solid rgb(228, 228, 228);
}

.service_price {
  padding: 0px 4px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.service_sold {
  color: #68d284;
  font-weight: 400;
  font-size: 16px;
}

.service_info {
  padding: 0 8px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.service_heart_icon {
  cursor: pointer;
  position: absolute;
  right: 8px;
  top: 8px;
  padding: 2px;
  border-radius: 50%;
  background-color: #fff;
}

.service_eye_icon {
  cursor: pointer;
  position: absolute;
  right: 8px;
  top: 50px;
  padding: 2px;
  border-radius: 50%;
  background-color: #fff;
}

.service_cart_icon {
  cursor: pointer;
  position: absolute;
  right: 8px;
  top: 90px;
  padding: 2px;
  border-radius: 50%;
  background-color: #ffffff;
}

/* ---------- service detail card ---------------- */

.sdc_card {
  background-color: red;
}

.sdc_container {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 48px;
  left: 0;
  background: #ffffff95;
  z-index: 40;
  display: flex;
  align-items: center;
  justify-content: center;
}

/*  shadow-sm relative p-4 */
.sdc_wrap {
  width: 80%;
  height: 80vh;
  overflow-y: scroll;
  background: #fff;
  border-radius: 20px;
  box-shadow: 0px 5px 15px gray;
  position: relative;
  padding: 4px;
  /* background: #000; */
  margin-bottom: 40px;
}

.sdc_cross {
  position: absolute;
  right: 12px;
  top: 12px;
  z-index: 50;
}

.sdc_item {
  display: flex;
  width: 100%;
}

.sdc_item_left {
  width: 50%;
}

.sdc_image {
  height: 480px;
  width: 480px;
  min-width: 480px;
}

.sdc_shop_avatar {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin-right: 4px;
}

.sdc_ratings {
  padding-bottom: 12px;
  font-size: 14px;
  color: gray;
  margin-left: 10px;
}

.sdc_message {
  background-color: #000;
  width: 160px;
  font-size: 16px;
  color: #fff;
  margin-top: 10px;
  border-radius: 5px;
  height: 46px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.sdc_soldout {
  font-size: 16px;
  color: red;
  margin-top: 20px;
  margin-left: 20px;
}

.sdc_shop_name {
  font-size: 16px;
  color: var(--blue-color);
}

.sdc_right {
  width: 50%;
}

.sdc_name {
  width: 80%;
  padding: 20px 20px 0px 20px;
}

.sdc_description {
  font-size: 18px !important;
  color: gray !important;
  font-weight: 300;
  margin-top: 20px;
}

.sdc_price_wrap {
  display: flex;
  padding-top: 12px;
}

.sdc_count {
  color: gray;
  font-weight: 400;
  padding: 10px 20px;
}

.sdc_decremement_button {
  color: #fff;
  font-weight: 600;
  font-size: 18px;

  padding: 10px 16px;
  border-radius: 5px;
  box-shadow: 0px 5px 15px lightblue;
  transition: cubic-bezier(0.075, 0.82, 0.165, 1);
  border: none;
  cursor: pointer;
  background: rgb(94, 151, 183);
  background: linear-gradient(
    95deg,
    rgba(94, 151, 183, 1) 31%,
    rgba(94, 151, 183, 0.6895892145920868) 74%
  );
}

.sdc_increment_button {
  font-size: 18px;
  color: #fff;
  font-weight: 600;
  padding: 10px 16px;
  border-radius: 5px;
  box-shadow: 0px 5px 15px lightblue;
  transition: cubic-bezier(0.075, 0.82, 0.165, 1);
  border: none;
  cursor: pointer;
  background: rgb(94, 151, 183);
  background: linear-gradient(
    263deg,
    rgba(94, 151, 183, 1) 31%,
    rgba(94, 151, 183, 0.6895892145920868) 74%
  );
}

.sdc_discount_price {
  font-size: 24px;
  font-weight: 400;
  color: var(--pri-blue);
  text-decoration: line-through;
  padding-left: 20px;
}

.sdc_cart_button {
  margin-top: 20px;
  border-radius: 5px;
  width: 200px;
  height: 40px;
  background-color: red;
  font-size: 20px;
  font-weight: 400;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

/* -------------- media queries ----------------- */

@media screen and (max-width: 1080px) {
  .service_card {
    width: 310px;
    margin: 0px 10px 20px 10px;
  }
  .service_name {
    font-size: 18px;
  }
}

@media screen and (max-width: 1044px) {
  .service_card {
    width: 320px;
  }
}

@media screen and (max-width: 736px) {
  .service_card {
    width: 340px;
  }
}

/* -------------- media queries ----------------- */
